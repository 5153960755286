import React from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import { ActionLink, CloseButtonIcon, ensureExhaustive, Header } from "halifax";
import { IdEnum, IResult } from "redmond";

import { PATH_HOME } from "../../../../utils/paths";
import { OccupancySelection } from "../MobilePremierCollectionSearchControl/components/OccupancySelection";
import { StayTypeToggleSwitch } from "../PremierCollectionSearchControl/components/StayTypeToggle";
import { LocationSearch, MobileCalendarPicker } from "./components";
import { MegaMenuData, MobileMegaMenu } from "./components/MobileMegaMenu";
import { MobilePremierCollectionSearchV3ConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

enum SearchStepEnum {
  LocationSearch,
  MegaMenu,
  CalendarPicker,
}

type MobilePremierCollectionSearchStep =
  | { step: SearchStepEnum.LocationSearch }
  | { step: SearchStepEnum.CalendarPicker }
  | { step: SearchStepEnum.MegaMenu; item?: MegaMenuData };

type NonEmptyArray<T> = [T, ...T[]];

export interface IMobilePremierCollectionSearchControlV3Props
  extends MobilePremierCollectionSearchV3ConnectorProps,
    RouteComponentProps {
  supportsMegaMenu?: boolean;
  onSelectLocation?: (value: IResult) => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
  isLifestyleCollection?: boolean;
  includesLifestyleCollection?: boolean;
  includesVacationRentals?: boolean;
}

export const MobilePremierCollectionSearchControlV3 = (
  props: IMobilePremierCollectionSearchControlV3Props
) => {
  const {
    history,
    supportsMegaMenu,
    megaMenuRegions,
    setLocation,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    onSelectLocation,
    onSelectDates,
    isLifestyleCollection,
    includesLifestyleCollection,
    includesVacationRentals,
  } = props;

  const [navigationStack, setNavigationStack] = React.useState<
    NonEmptyArray<MobilePremierCollectionSearchStep>
  >([{ step: SearchStepEnum.LocationSearch }]);
  const pushToNavigationStack = (step: MobilePremierCollectionSearchStep) =>
    setNavigationStack([...navigationStack, step]);

  const currentStep = React.useMemo(
    () => navigationStack.slice(-1)[0],
    [navigationStack]
  );
  const pushStep = React.useCallback(
    (
      ...args:
        | [step: SearchStepEnum]
        | [step: SearchStepEnum.MegaMenu, item: MegaMenuData]
    ) => {
      const [step, item] = args;
      pushToNavigationStack({ step, item });
    },
    [navigationStack]
  );

  const handleGoBack = () => {
    if (navigationStack.length > 1) {
      setNavigationStack(
        navigationStack.slice(
          0,
          -1
        ) as NonEmptyArray<MobilePremierCollectionSearchStep>
      );
    }
  };

  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [currentStep]);

  return (
    <Box
      className={clsx("mobile-premier-collection-search-v3", {
        "date-range-picker": currentStep.step === SearchStepEnum.CalendarPicker,
      })}
    >
      <Header
        className="homepage"
        center={
          <HeaderCenterSection
            currentStep={currentStep}
            isLifestyleCollection={isLifestyleCollection}
            includesLifestyleCollection={includesLifestyleCollection}
          />
        }
        left={
          <ActionLink
            className={clsx("mobile-premier-collection-search-header-go-back", {
              hidden: navigationStack.length <= 1,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-premier-collection-search-header-close"
            onClick={() => {
              history.push(PATH_HOME);
              hasLocationAutocompleteError &&
                setLocationAutocompleteError(false);
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep.step === SearchStepEnum.LocationSearch && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
          }}
        >
          {includesVacationRentals && <StayTypeToggleSwitch isMobile />}
          <OccupancySelection />
          <LocationSearch
            onComplete={() => pushStep(SearchStepEnum.CalendarPicker)}
            onSelectLocation={onSelectLocation}
            onOpenMegaMenu={
              megaMenuRegions != null && supportsMegaMenu
                ? () => pushStep(SearchStepEnum.MegaMenu)
                : undefined
            }
            isLifestyleCollection={isLifestyleCollection}
            includesLifestyleCollection={includesLifestyleCollection}
            includesVacationRentals={includesVacationRentals}
            history={history}
          />
        </Box>
      )}
      {currentStep.step === SearchStepEnum.MegaMenu && (
        <MobileMegaMenu
          data={currentStep.item}
          navigate={(item) => pushStep(SearchStepEnum.MegaMenu, item)}
          select={(item) => {
            const result: IResult = {
              id: {
                Id: IdEnum.Lodgings,
                lodgingSelection: item.lodgingSelection,
              },
              label: item.label,
            };
            setLocation(result);
            onSelectLocation?.(result);
            pushStep(SearchStepEnum.CalendarPicker);
          }}
        />
      )}
      {currentStep.step === SearchStepEnum.CalendarPicker && (
        <MobileCalendarPicker
          onSelectDates={onSelectDates}
          includesVacationRentals={includesVacationRentals}
        />
      )}
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: MobilePremierCollectionSearchStep;
  isLifestyleCollection?: boolean;
  includesLifestyleCollection?: boolean;
}

const HeaderCenterSection = ({
  currentStep,
  isLifestyleCollection,
  includesLifestyleCollection,
}: IHeaderCenterSectionProps) => {
  const headerText = React.useMemo(() => {
    switch (currentStep.step) {
      case SearchStepEnum.CalendarPicker:
        return textConstants.UPDATE_CALENDAR_TEXT;
      case SearchStepEnum.MegaMenu:
        if (currentStep.item != null) {
          return currentStep.item.data.label;
        }
        return "Explore";
      case SearchStepEnum.LocationSearch:
        return isLifestyleCollection
          ? textConstants.LIFESTYLE_COLLECTION_HEADER
          : includesLifestyleCollection
          ? textConstants.PREMIUM_STAYS_HEADER
          : textConstants.PREMIER_COLLECTION_HEADER;
      default:
        return ensureExhaustive(currentStep);
    }
  }, [currentStep, isLifestyleCollection, includesLifestyleCollection]);
  return (
    <Box className="header-center-section">
      {headerText === textConstants.UPDATE_CALENDAR_TEXT ? (
        <Box className="calendar-header">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      ) : (
        <Box className="mobile-premier-collection-search-location-label">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      )}
    </Box>
  );
};
