import React, { useEffect, useState } from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as H from "history";

import {
  ActionButton,
  Icon,
  IconName,
  InfoIcon,
  MobilePremierCollectionAutocompleteOptions,
} from "halifax";
import { IResult, StayTypesEnum } from "redmond";

import { PATH_HOME, PATH_HOTEL } from "../../../../../../utils/paths";
import { LocationSearchConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: (value?: IResult) => void;
  onEditDates?: boolean;
  history: H.History;
  onSelectLocation?: (value: IResult) => void;
  onOpenMegaMenu?: () => void;
  isLifestyleCollection?: boolean;
  includesLifestyleCollection?: boolean;
  includesVacationRentals?: boolean;
}
export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    location,
    loading,
    onComplete,
    setLocation,
    locationCategories,
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    setLocationSearchString: setReduxLocationSearchString,
    onSelectLocation,
    onOpenMegaMenu,
    isLifestyleCollection = false,
    includesLifestyleCollection = false,
    stayType,
    setVacationRentalsLocation,
    includesVacationRentals,
    vacationRentalsLocation,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>(
    location?.label || vacationRentalsLocation?.label || ""
  );

  useEffect(() => {
    if (stayType === StayTypesEnum.VacationRentals && includesVacationRentals) {
      setLocationSearchString(vacationRentalsLocation?.label ?? "");
    } else {
      setLocationSearchString(location?.label ?? "");
    }
  }, [stayType, includesVacationRentals]);

  const onLocationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasLocationAutocompleteError && setLocationAutocompleteError(false);

    stayType === StayTypesEnum.VacationRentals && includesVacationRentals
      ? setVacationRentalsLocation(null)
      : setLocation(null);
    setLocationSearchString(event.target.value);
    if (event.target.value) {
      setReduxLocationSearchString(event.target.value);
    }
  };

  const onLocationSelected = (value: IResult | null) => {
    if (!value) {
      return;
    }
    setLocationSearchString(value.label);
    stayType === StayTypesEnum.VacationRentals && includesVacationRentals
      ? setVacationRentalsLocation(value)
      : setLocation(value);
    onContinue(value);
    onSelectLocation?.(value);
  };

  const onContinue = (value?: IResult) => {
    onComplete?.(value);
  };
  const onClearLocationField = () => {
    stayType === StayTypesEnum.VacationRentals && includesVacationRentals
      ? setVacationRentalsLocation(null)
      : setLocation(null);
    setLocationSearchString("");
    setReduxLocationSearchString("");
  };
  return (
    <Box className="mobile-premier-collection-search-location redesign-v3">
      <Box className="mobile-premier-collection-search-location-input-wrapper">
        <Box className="mobile-premier-collection-search-location-input-field">
          <Icon name={IconName.B2BMapPin} />
          <TextField
            className={clsx("origin-auto-complete", "mobile")}
            onChange={(value) => onLocationSearch(value)}
            value={locationSearchString}
            label={textConstants.LOCATION_SEARCH_PREFIX}
            InputProps={{
              disableUnderline: true,
            }}
          />
          {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
          <FontAwesomeIcon
            className={clsx("icon", { hidden: !locationSearchString })}
            icon={faTimesCircle}
            onClick={onClearLocationField}
          />
        </Box>
        {hasLocationAutocompleteError && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasLocationAutocompleteError &&
                  setLocationAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}
        {onOpenMegaMenu && stayType === StayTypesEnum.Hotels && (
          <Box className="pc-mega-menu-button" onClick={onOpenMegaMenu}>
            <Icon name={IconName.Compass} />
            <Typography variant="body1">
              Explore all hotel and resort destinations
            </Typography>
            <Icon name={IconName.RightChevronIcon} />
          </Box>
        )}
      </Box>
      <MobilePremierCollectionAutocompleteOptions
        value={null}
        valueCategories={locationCategories}
        setValue={onLocationSelected}
        loading={loading}
        loadingText={textConstants.LOADING_TEXT}
        hideSidePadding
        listHeadingText={
          !locationSearchString
            ? stayType === StayTypesEnum.VacationRentals
              ? textConstants.VACATION_RENTALS_AUTOCOMPLETE_RESULTS_LABEL
              : includesVacationRentals
              ? textConstants.HOTELS_WITH_VR_AUTOCMPLETE_RESULTS_LABEL
              : isLifestyleCollection
              ? textConstants.LC_AUTOCOMPLETE_RESULTS_LABEL
              : includesLifestyleCollection
              ? textConstants.PREMIUM_STAYS_AUTOCOMPLETE_RESULTS_LABEL
              : textConstants.AUTOCOMPLETE_RESULTS_LABEL
            : undefined
        }
        listHeadingIcon={
          !locationSearchString && includesVacationRentals ? (
            stayType == StayTypesEnum.VacationRentals ? (
              <Icon name={IconName.VacationRentalsToggleIcon} />
            ) : (
              <Icon name={IconName.HotelFunnelIcon} />
            )
          ) : undefined
        }
        noOptionsElement={
          <Box className="no-options-content">
            <Typography className="no-options-text" variant="body1">
              {stayType === StayTypesEnum.VacationRentals
                ? textConstants.NO_VR_OPTIONS_TEXT
                : isLifestyleCollection
                ? textConstants.NO_LC_OPTIONS_TEXT
                : includesLifestyleCollection
                ? textConstants.NO_PREMIUM_STAYS_OPTIONS_TEXT
                : textConstants.NO_OPTIONS_TEXT}
            </Typography>
            {stayType === StayTypesEnum.Hotels ? (
              <Box className="no-options-link-content">
                <Typography variant="body2">
                  {isLifestyleCollection
                    ? textConstants.NO_LC_OPTIONS_TO_HOTELS_TEXT
                    : includesLifestyleCollection
                    ? textConstants.NO_PREMIUM_STAYS_OPTIONS_TO_HOTELS_TEXT
                    : textConstants.NO_OPTIONS_TO_HOTELS_TEXT}
                </Typography>
                <Link
                  className="no-options-link"
                  onClick={() => {
                    history.push(PATH_HOTEL);
                  }}
                >
                  <Typography className="no-options-link-text">
                    {textConstants.SEARCH_ALL_HOTELS}
                  </Typography>
                </Link>
              </Box>
            ) : undefined}
          </Box>
        }
        className={
          !!location || !!vacationRentalsLocation
            ? "with-continue-button"
            : undefined
        }
      />
      {(!!location || !!vacationRentalsLocation) && (
        <ActionButton
          className="mobile-autocomplete-continue-button"
          message={textConstants.CONTINUE}
          onClick={() => {
            onContinue(location ?? vacationRentalsLocation ?? undefined);
          }}
        />
      )}
    </Box>
  );
};
