import { Amount, Prices, RewardsPrice, Uuid } from "../../common";
import {
  HotelCancellationPolicy,
  HotelCancellationPolicyV2,
  HotelPricing,
  LodgingId,
  PriceQuote,
  RoomInfo,
} from "../../hotels-module";
import {
  AirlineMap,
  AirportMap,
  SingleFlightItineraryResponse,
  FlightItinerarySlice,
  RewardsAmountWithRate,
} from "../../trips-module";
import {
  DullesUtas,
  Flights,
  MultiMethod,
  PaymentV2,
  Platform,
  Route,
  ShopFilter,
  WalletDetailsResponse,
} from "../index";
import { AgentLocator, Payment } from "./payment-machine";

export interface CfarId {
  productId: string;
  policyId: string;
  quoteId?: string;
}

export interface ChfarId {
  productId: string;
  policyId: string;
}

export interface CfarDiscount {
  discountType: string;
  discountPercentage: number;
  originalPremiumAmount: Prices;
}
export interface CfarOffer {
  id: CfarId;
  premiumPerPax: Prices;
  policyData?: CfarOfferPolicyData;
  discount?: CfarDiscount;
  AncillaryOffer: AncillaryOfferEnum.CfarOffer;
}

export interface ChfarOffer {
  id: ChfarId;
  premiumPerPax: Prices;
  policyData?: ChfarOfferPolicyData;
  AncillaryOffer: AncillaryOfferEnum.ChfarOffer;
}

export interface AncillaryOfferPricing {
  perPassengerPrices: Prices;
  totalPrices: Prices;
}

export interface DelayId {
  productId: string;
  policyId: string;
}

export interface DelayExtraProperties {
  policyDetails: DelayPolicyDetails;
}

export interface DelayOffer {
  id: DelayId;
  premiumPricing: AncillaryOfferPricing;
  coveragePricing: AncillaryOfferPricing;
  AncillaryOffer: AncillaryOfferEnum.DelayOffer;
  extraProperties?: DelayExtraProperties;
}

export interface MissedConnectionId {
  productId: string;
  policyId: string;
}

export interface MissedConnectionExtraProperties {
  policyDetails:
  | MissedConnectionFixedPolicyDetails
  | MissedConnectionPercentagePolicyDetails;
}

export interface MissedConnectionOffer {
  id: MissedConnectionId;
  premiumPricing: AncillaryOfferPricing;
  coveragePricing: AncillaryOfferPricing;
  AncillaryOffer: AncillaryOfferEnum.MissedConnectionOffer;
  extraProperties: MissedConnectionExtraProperties;
}

export type AncillaryOffer =
  | CfarOffer
  | ChfarOffer
  | DelayOffer
  | MissedConnectionOffer;

export enum AncillaryOfferEnum {
  CfarOffer = "CfarOffer",
  ChfarOffer = "ChfarOffer",
  DelayOffer = "DelayOffer",
  MissedConnectionOffer = "MissedConnectionOffer",
}

export interface CfarOfferPolicyData {
  premiumPercentage?: number;
  cashCoveragePercentage: number;
  offerType: string;
}

// any changes from the cfar offer policy data
export interface ChfarOfferPolicyData {
  premiumPercentage?: number;
  cashCoveragePercentage: number;
  offerType: string;
}

export type AncillaryFetchOfferFailure =
  | AncillaryFareNotFound
  | AncillaryTripNotFound
  | AncillaryUnknown;

export type AncillaryFetchOfferSuccess =
  | MultiTicketV2
  | SingleTicket
  | MultiCity;

export enum AncillaryPostBookingOfferResponseEnum {
  PostBookingOfferNone = "PostBookingOfferNone",
  PostBookingOfferFound = "PostBookingOfferFound",
}

export interface AncillaryPostBookingFetchOfferFailure {
  PostBookingOfferResponseV2: AncillaryPostBookingOfferResponseEnum.PostBookingOfferNone;
}

type NonEmptyArray<T> = [T, ...T[]] & { 0: T };

export interface AncillaryPostBookingFetchOfferSuccess {
  offerId: string;
  itineraryId: Uuid;
  itinerary: SingleFlightItineraryResponse;
  ui: AncillaryPostBookingOfferUI;
  destination?: string;
  wallet: WalletDetailsResponse;
  date?: string;
  choices: NonEmptyArray<PostBookingOfferChoice>;
  payments: NonEmptyArray<Payment>;
  emailAddress: string;
  phoneNumber?: string;
  multiMethod: MultiMethod;
  passengers: string[];
  PostBookingOfferResponseV2: AncillaryPostBookingOfferResponseEnum.PostBookingOfferFound;
}

export interface AncillaryPostBookingOfferUI {
  image: string;
  upcomingFlightText: string;
  name: string;
}

export interface PostBookingOfferChoice {
  id: Uuid;
  policyDetails: PostBookingOfferChoicePolicyDetails;
  prices: Prices;
  purchaseRequest: AncillaryPostBookingPurchaseRequestNoPayment;
}

export interface PostBookingTrackingProperties {
  [key: string]: any;
}

export interface PostBookingOfferChoicePolicyDetails {
  premiumAmount: {
    perPaxAmount: number;
    paxCount: number;
    currency: string;
    totalAmount: number;
  };
  trackingProperties: PostBookingTrackingProperties;
  policyDetails: DelayPolicyDetails | MissedConnectionFixedPolicyDetails;
  PolicyDetails: "DelayPolicyDetails" | "MCPolicyDetails";
}

export interface PostBookingOfferChoiceDelayPolicyDetails
  extends PostBookingOfferChoicePolicyDetails {
  policyDetails: DelayPolicyDetails;
  PolicyDetails: "DelayPolicyDetails";
}

export interface PostBookingOfferChoiceMissedConnectionPolicyDetails
  extends PostBookingOfferChoicePolicyDetails {
  policyDetails: MissedConnectionFixedPolicyDetails;
  PolicyDetails: "MCPolicyDetails";
}

export interface DelayPolicyDetails {
  premium: {
    amount: number;
    currency: string;
  };
  coverage: {
    amount: number;
    currency: string;
  };
  minDelayMinutes?: number;
}

export interface MissedConnectionFixedPolicyDetails {
  premium: {
    amount: number;
    currency: string;
  };
  coverage: {
    amount: number;
    currency: string;
  };
  minDelayMinutes?: number;
}

export interface MissedConnectionPercentagePolicyDetails {
  minDelayMinutes?: number;
}

export enum AncillaryPostBookingPurchaseResponseEnum {
  PaymentFailed = "PaymentFailed",
  PurchaseSuccess = "PurchaseSuccess",
  Polling = "Polling",
}

export interface AncillaryPostBookingPurchaseFailure {
  PostBookingOfferPurchaseResponseV2: AncillaryPostBookingPurchaseResponseEnum.PaymentFailed;
}

export interface AncillaryPostBookingPurchasePolling {
  state: AncillaryPostBookingPurchaseState;
  PostBookingOfferPurchaseResponseV2: AncillaryPostBookingPurchaseResponseEnum.Polling;
}

export interface AncillaryPostBookingPurchaseSuccess {
  itineraryId: string;
  emailAddress: string;
  trackingProperties: PostBookingTrackingProperties;
  rewardsPrice?: RewardsPrice;
  PostBookingOfferPurchaseResponseV2: AncillaryPostBookingPurchaseResponseEnum.PurchaseSuccess;
}

export enum AncillaryPostBookingKindEnum {
  NotCalled = "NotCalled",
  NoOffer = "NoOffer",
  FetchOffer = "FetchOffer",
  ValidOffer = "ValidOffer",
  Confirmation = "Confirmation",
}

export interface AncillaryPostBookingNotCalled {
  state: AncillaryPostBookingKindEnum.NotCalled;
}

export interface AncillaryPostBookingNoOffer {
  state: AncillaryPostBookingKindEnum.NoOffer;
}

export interface AncillaryPostBookingFetchOffer {
  state: AncillaryPostBookingKindEnum.FetchOffer;
}

export enum AncillaryPostBookingPurchaseKindEnum {
  NotCalled = "NotCalled",
  Failed = "Failed",
  InProgress = "InProgress",
  Success = "Success",
}

export interface AncillaryPostBookingPurchaseStateNotCalled {
  state: AncillaryPostBookingPurchaseKindEnum.NotCalled;
}

export interface AncillaryPostBookingPurchaseStateFailed {
  response: AncillaryPostBookingPurchaseFailure;
  state: AncillaryPostBookingPurchaseKindEnum.Failed;
}

export interface AncillaryPostBookingPurchaseStateInProgress {
  request: AncillaryPostBookingPurchaseRequest;
  state: AncillaryPostBookingPurchaseKindEnum.InProgress;
}

export interface AncillaryPostBookingPurchaseStateSuccess {
  response: AncillaryPostBookingPurchaseSuccess;
  state: AncillaryPostBookingPurchaseKindEnum.Success;
}

export type AncillaryPostBookingAttemptPurchase =
  | AncillaryPostBookingPurchaseStateNotCalled
  | AncillaryPostBookingPurchaseStateFailed
  | AncillaryPostBookingPurchaseStateInProgress
  | AncillaryPostBookingPurchaseStateSuccess;

export interface AncillaryPostBookingValidOffer {
  offer: AncillaryPostBookingFetchOfferSuccess;
  attemptState: AncillaryPostBookingAttemptPurchase;
  state: AncillaryPostBookingKindEnum.ValidOffer;
}

export interface AncillaryPostBookingConfirmation {
  response: AncillaryPostBookingPurchaseSuccess;
  state: AncillaryPostBookingKindEnum.Confirmation;
}

export type AncillaryPostBookingState =
  | AncillaryPostBookingNotCalled
  | AncillaryPostBookingNoOffer
  | AncillaryPostBookingFetchOffer
  | AncillaryPostBookingValidOffer
  | AncillaryPostBookingConfirmation;

export enum AncillaryFailureEnum {
  FareNotFound = "FareNotFound",
  TripNotFound = "TripNotFound",
  Unknown = "Unknown",
}

export enum AncillaryKindEnum {
  CancelOrChange = "CancelOrChange",
  Cfar = "Cfar",
  Chfar = "Chfar",
  Delay = "Delay",
  Insurance = "Insurance",
  Luggage = "Luggage",
  MissedConnection = "MissedConnection",
}

export interface CancelOrChange {
  AncillaryKind: AncillaryKindEnum.CancelOrChange;
}

export interface Cfar {
  AncillaryKind: AncillaryKindEnum.Cfar;
}

export interface Chfar {
  AncillaryKind: AncillaryKindEnum.Chfar;
}

export interface Delay {
  AncillaryKind: AncillaryKindEnum.Delay;
}

export interface Insurance {
  AncillaryKind: AncillaryKindEnum.Insurance;
}

export interface Luggage {
  AncillaryKind: AncillaryKindEnum.Luggage;
}

export interface MissedConnection {
  AncillaryKind: AncillaryKindEnum.MissedConnection;
}

export type AncillaryKind =
  | CancelOrChange
  | Cfar
  | Chfar
  | Delay
  | Insurance
  | Luggage
  | MissedConnection;

export interface AncillaryFetchOfferRequest {
  tripId: string;
  fareId: string;
  tripFilter: ShopFilter;
  passengers: { [key: string]: number };
  priceFreezeId?: string;
  requestedProducts?: AncillaryKindEnum[];
}

export type AncillaryFetchOfferResponse =
  | AncillaryFetchOfferFailure
  | AncillaryFetchOfferSuccess;

export interface AncillaryPostBookingFetchOfferRequest {
  itineraryId?: string;
  referenceDateTime: String;
  ignoreViewOfferTracking?: boolean;
}

export interface AncillaryPostBookingPurchaseRequestNoPayment {
  itineraryId: string;
  protectionId: object;
  policyDetails: object;
}

export interface AncillaryPostBookingPurchaseRequest
  extends AncillaryPostBookingPurchaseRequestNoPayment {
  itineraryId: string;
  protectionId: object;
  policyDetails: object;
  payments: PaymentV2[];
  accountReferenceId?: string;
  multiMethod?: MultiMethod;
}

export interface AncillaryPostBookingPurchaseRequestScheduler {
  request: AncillaryPostBookingPurchaseRequest;
  state: AncillaryPostBookingPurchaseState;
}

export enum AncillaryPostBookingPurchaseStateEnum {
  ScheduleQuote = "ScheduleQuote",
  PollingQuote = "PollingQuote",
  SchedulePurchase = "SchedulePurchase",
  PollingPurchase = "PollingPurchase",
}

export interface AncillaryPostBookingPurchaseStateScheduleQuote {
  PostBookingState: AncillaryPostBookingPurchaseStateEnum.ScheduleQuote;
}

export interface AncillaryPostBookingPurchaseStatePollingQuote {
  PostBookingState: AncillaryPostBookingPurchaseStateEnum.PollingQuote;
  token: string;
}

export interface AncillaryPostBookingPurchaseStateSchedulePurchase {
  PostBookingState: AncillaryPostBookingPurchaseStateEnum.SchedulePurchase;
  token: string;
}

export interface AncillaryPostBookingPurchaseStatePollingPurchase {
  PostBookingState: AncillaryPostBookingPurchaseStateEnum.PollingPurchase;
  token: string;
}

export type AncillaryPostBookingPurchaseState =
  | AncillaryPostBookingPurchaseStateScheduleQuote
  | AncillaryPostBookingPurchaseStatePollingQuote
  | AncillaryPostBookingPurchaseStateSchedulePurchase
  | AncillaryPostBookingPurchaseStatePollingPurchase;

export type AncillaryPostBookingFetchOfferResponse =
  | AncillaryPostBookingFetchOfferFailure
  | AncillaryPostBookingFetchOfferSuccess;

export type AncillaryPostBookingPurchaseResponse =
  | AncillaryPostBookingPurchaseFailure
  | AncillaryPostBookingPurchasePolling
  | AncillaryPostBookingPurchaseSuccess;

export enum AncillaryFetchOfferResponseEnum {
  SingleTicket = "SingleTicket",
  MultiTicketV2 = "MultiTicketV2",
  MultiCity = "MultiCity",
}

export interface MultiTicketV2 {
  FetchOfferResponseV2: AncillaryFetchOfferResponseEnum.MultiTicketV2;
  ancillaryOffers: AncillaryOffer[];
  outboundUtas?: DullesUtas;
  returnUtas?: DullesUtas;
}

export interface SingleTicket {
  FetchOfferResponseV2: AncillaryFetchOfferResponseEnum.SingleTicket;
  ancillaryOffers: AncillaryOffer[];
  utas?: DullesUtas;
}

export interface MultiCity {
  FetchOfferResponseV2: AncillaryFetchOfferResponseEnum.MultiCity;
  ancillaryOffers: AncillaryOffer[];
  utas?: DullesUtas[];
}

export interface AncillaryFareNotFound {
  Failure: AncillaryFailureEnum.FareNotFound;
}

export interface AncillaryTripNotFound {
  Failure: AncillaryFailureEnum.TripNotFound;
}

export interface AncillaryUnknown {
  message: string;
  Failure: AncillaryFailureEnum.Unknown;
}

export enum HotelCfarQuotesFailureEnum {
  ShopFailure = "ShopFailure",
  Unknown = "Unknown",
}

export interface HotelCfarShopFailure {
  msg: string;
  Failure: HotelCfarQuotesFailureEnum.ShopFailure;
}

export interface HotelCfarUnknownFailure {
  Failure: HotelCfarQuotesFailureEnum.Unknown;
}

export type HotelCfarQuotesFailure =
  | HotelCfarShopFailure
  | HotelCfarUnknownFailure;

export interface HotelCfarNonEligible {
  cancellationPolicy: HotelCancellationPolicy;
  CfarQuote: CfarQuoteEnum.HotelCfarNonEligible;
}

export enum HotelNoQuoteReasonEnum {
  DelosDecline = "DelosDecline",
  Unspeciied = "Unspeciied",
  DelosFail = "DelosFail",
  Unexpected = "Unexpected",
  ValidationFailed = "ValidationFailed",
}
export interface DelosDecline {
  NoQuoteReason: HotelNoQuoteReasonEnum.DelosDecline;
}

export interface DelosFail {
  NoQuoteReason: HotelNoQuoteReasonEnum.DelosFail;
}

export interface Unspeciied {
  NoQuoteReason: HotelNoQuoteReasonEnum.Unspeciied;
}

export interface Unexpected {
  NoQuoteReason: HotelNoQuoteReasonEnum.Unexpected;
}

export interface ValidationFailed {
  NoQuoteReason: HotelNoQuoteReasonEnum.ValidationFailed;
}

export type HotelNoQuoteReason =
  | DelosDecline
  | DelosFail
  | Unexpected
  | Unspeciied
  | ValidationFailed;

export interface HotelCfarNoQuote {
  reason: HotelNoQuoteReason;
  detail: string;
  pricingDescription?: string;
  CfarQuoteResult: CfarQuoteResultEnum.HotelCfarNoQuote;
}

export interface QuoteId {
  value: string;
}

interface Coverage {
  amount: Amount;
  percentage: number;
}

export interface CoverageOverriding {
  overridingCoverage: Coverage;
  overridingStrategy: string;
}

export interface HotelCfarQuote {
  id: QuoteId;
  premiumPrices: Prices;
  premiumPricesPerNight?: Prices;
  coverageAmount: Amount;
  coverageOverriding?: CoverageOverriding;
  coveragePercentage: number;
  pricingDescription?: string;
  CfarQuoteResult: CfarQuoteResultEnum.HotelCfarQuote;
}

export interface HotelPriceQuoteWithAncillaries {
  hotelQuoteData: PriceQuote;
  hotelAncillaryQuotes?: HotelAncillaryQuotes;
  combinedPricing: HotelPricing;
}

export interface HotelAncillaryQuotes {
  cfar?: HotelCfarQuote;
}

export type CfarQuoteResult = HotelCfarNoQuote | HotelCfarQuote;

export enum CfarQuoteResultEnum {
  HotelCfarQuote = "Quote",
  HotelCfarNoQuote = "NoQuote",
}

interface RateId {
  value: string;
}

export interface HotelCfarQuoteAdditionalInfo {
  shouldRefundCfarPremium?: boolean;
  baseRefundPrices?: Prices;
  totalRefundPrices?: Prices;
  isRefundableByCapitalOne?: boolean;
}

export interface HotelCfarEligible {
  rateId?: RateId;
  additionalInfo: HotelCfarQuoteAdditionalInfo;
  cfarQuoteResult: CfarQuoteResult;
  cancellationPolicy: HotelCancellationPolicy;
  cancellationPolicyV2: HotelCancellationPolicyV2;
  CfarQuote: CfarQuoteEnum.HotelCfarEligible;
}

export type ProductCfarQuote = HotelCfarEligible | HotelCfarNonEligible;

export enum CfarQuoteEnum {
  HotelCfarNonEligible = "NonEligible",
  HotelCfarEligible = "Eligible",
}

export interface RoomInfoCfarQuote {
  roomInfo: RoomInfo;
  cfarQuotes: ProductCfarQuote[];
}

export enum GetCfarQuotesResponseEnum {
  Failure = "Failure",
  Success = "Success",
}

export interface GetCfarQuotesSuccess {
  lodgingId: LodgingId;
  roomInfoCfarQuotes: RoomInfoCfarQuote[];
  GetCfarQuotesResponse: GetCfarQuotesResponseEnum.Success;
  shouldDisableRefundableRooms?: boolean;
}

export interface GetCfarQuotesRequest {
  opaqueRequest: string;
  opaqueCfarQuotesRequest?: string | null;
}

export type GetCfarQuotesResponse =
  | HotelCfarQuotesFailure
  | GetCfarQuotesSuccess;

export interface FetchCfarOfferRequestV2 {
  tripId: string;
  fareId: string;
}

export interface BatchFetchCfarOfferRequestV2 {
  requests: FetchCfarOfferRequestV2[];
}

export enum FetchCfarOfferResponseV2Enum {
  Failure = "Failure",
  MultiTicketV2 = "MultiTicketV2",
  SingleTicket = "SingleTicket",
}

export interface CfarOfferMultiTicketV2 {
  cfarOffer?: CfarOffer;
  outboundUtas?: DullesUtas;
  returnUtas?: DullesUtas;
  displayRefundAmount?: number;
  FetchCfarOfferResponseV2: FetchCfarOfferResponseV2Enum.MultiTicketV2;
}

export interface CfarOfferSingleTicket {
  cfarOffer?: CfarOffer;
  utas?: DullesUtas;
  displayRefundAmount?: number;
  FetchCfarOfferResponseV2: FetchCfarOfferResponseV2Enum.SingleTicket;
}

export enum CfarOfferFailureEnum {
  FareNotFound = "FareNotFound",
  TripNotFound = "TripNotFound",
  Unknown = "Unknown",
}

export interface CfarOfferFareNotFound {
  Failure: CfarOfferFailureEnum.FareNotFound;
}

export interface CfarOfferTripNotFound {
  Failure: CfarOfferFailureEnum.TripNotFound;
}

export interface CfarOfferUnknown {
  message: string;
  Failure: CfarOfferFailureEnum.Unknown;
}

export type CfarOfferFailure =
  | CfarOfferFareNotFound
  | CfarOfferTripNotFound
  | CfarOfferUnknown;

export type FetchCfarOfferSuccessV2 =
  | CfarOfferMultiTicketV2
  | CfarOfferSingleTicket;

export type FetchCfarOfferResponseV2 =
  | CfarOfferFailure
  | FetchCfarOfferSuccessV2;

export interface BatchFetchCfarOfferResponseV2 {
  responseByTripId: {
    [key: string]: { [key: string]: FetchCfarOfferResponseV2 };
  };
}

export enum CfarEligibilityResponseEnum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
  Failure = "Failure",
}

export interface CancellationFareRulesRequest {
  itineraryId: string;
}

// Manually created from the tsgen type to only be specific for cfar
export type CancellationFareRulesResponse =
  | CancellationFareRulesFailure
  | CancellationFareRulesSuccess;

export enum CancellationFareRulesResponseEnum {
  Failure = "Failure",
  Success = "Success",
}

export interface CancellationFareRulesFailure {
  Response: CancellationFareRulesResponseEnum.Failure;
  errors: FlightCfarFareRulesFailure[];
}

export interface FlightCfarFareRulesFailure {
  code: string;
  details?: string;
}

export interface CancellationFareRulesSuccess {
  value: CfarFareRulesSuccessResponse;
}

export interface ConfirmFlightCfarCancelRequest {
  itineraryId: string;
  cancelScenario: CfarFareRulesSuccessResponse;
}

export type CfarFareRulesSuccessResponse =
  | AirlineFullRefund
  | AirlinePartialRefundOrCfarCash
  | CancellationPending
  | CfarCash
  | CfarCashOrCfarFtc
  | CfarCashOrCfarFtcWithPenalty
  | ContactCustomerService
  | TicketedVoid
  | TooCloseToDeparture;

export enum CfarEnum {
  AirlineFullRefund = "AirlineFullRefund",
  AirlinePartialRefundOrCfarCash = "AirlinePartialRefundOrCfarCash",
  CfarCash = "CfarCash",
  CfarCashOrCfarFtc = "CfarCashOrCfarFtc",
  CfarCashOrCfarFtcWithPenalty = "CfarCashOrCfarFtcWithPenalty",
  ContactCustomerService = "ContactCustomerService",
  TicketedVoid = "TicketedVoid",
  TooCloseToDeparture = "TooCloseToDeparture",
  /*
    note: CancellationPending isn't a case that's defined in Tysons; it's converted from a CancellationPending scenario
    that is independent of whether it's Cfar or NonCfar. It's added so that the FE can easily extend the current switch statement.
  */
  CancellationPending = "CancellationPending",
}

interface CfarExercise {
  cfarId: CfarId;
  Cfar: CfarEnum;
  agentLocator?: AgentLocator;
}

export interface AirlineFullRefund extends CfarExercise {
  Cfar: CfarEnum.AirlineFullRefund;
  refundPrices: Prices;
  preparedPayment: string;
  isMultiCity: boolean;
  refundBreakdown?: RefundBreakdown;
}

export interface AirlinePartialRefundOrCfarCash extends CfarExercise {
  Cfar: CfarEnum.AirlinePartialRefundOrCfarCash;
  coveragePercent: number;
  penaltyPrices: Prices;
  refundPrices: Prices;
  preparedPayment: string;
  isMultiCity: boolean;
}

export interface CancellationPending {
  Cfar: CfarEnum.CancellationPending;
}

export interface CfarCash extends CfarExercise {
  Cfar: CfarEnum.CfarCash;
  coveragePercent: number;
  refundPrices: Prices;
  preparedPayment: string;
  isMultiCity: boolean;
  refundBreakdown?: RefundBreakdown;
}

export interface AirlineRefundAmounts {
  cash: Prices;
  ftc: Prices;
}

export interface RefundBreakdown {
  userCard?: Amount;
  rewards?: RewardsAmountWithRate;
  travelWalletCredits?: Amount;
  priceFreezeExerciseCreditAmount?: Amount;
}

export interface CfarCashOrCfarFtc extends CfarExercise {
  Cfar: CfarEnum.CfarCashOrCfarFtc;
  coveragePercent: number;
  refundPrices: Prices;
  ftcRefundPrices: Prices;
  preparedPaymentCash: string;
  preparedPaymentFtc: string;
  refundPricesByAirline: { [key: string]: AirlineRefundAmounts };
  useCash?: boolean;
  isMultiCity: boolean;
  refundBreakdown?: RefundBreakdown;
}

export interface CfarCashOrCfarFtcWithPenalty extends CfarExercise {
  Cfar: CfarEnum.CfarCashOrCfarFtcWithPenalty;
  coveragePercent: number;
  refundPrices: Prices;
  ftcRefundPrices: Prices;
  preparedPaymentCash: string;
  preparedPaymentFtc: string;
  refundPricesByAirline: { [key: string]: AirlineRefundAmounts };
  useCash?: boolean;
  isMultiCity: boolean;
  refundBreakdown?: RefundBreakdown;
}

export interface ContactCustomerService extends CfarExercise {
  Cfar: CfarEnum.ContactCustomerService;
}

export interface TicketedVoid extends CfarExercise {
  Cfar: CfarEnum.TicketedVoid;
  refundPrices: Prices;
  windowEnd: string;
  preparedPayment: string;
  isMultiCity: boolean;
  refundBreakdown?: RefundBreakdown;
}

export interface TooCloseToDeparture extends CfarExercise {
  Cfar: CfarEnum.TooCloseToDeparture;
}

export interface RebookSummaryRequest {
  route: Route;
  itineraryId: string;
  platform: Platform;
}

export type RebookSummaryResponse = RebookSummaryFailure | RebookSummarySuccess;

export enum RebookSummaryResponseEnum {
  RebookSummarySuccess = "RebookSummarySuccess",
  RebookSummaryFailure = "RebookSummaryFailure",
}

export interface TripContext {
  airports: AirportMap;
  airlines: AirlineMap;
}

export enum RebookSummaryTripType {
  ROUND_TRIP = "round_trip",
  ONE_WAY = "one_way",
}

export interface RebookSummarySuccess {
  originalSlice: FlightItinerarySlice;
  originalContext: TripContext;
  sameDayFlights: Flights;
  nextDayFlights: Flights;
  RebookSummaryResponse: RebookSummaryResponseEnum.RebookSummarySuccess;
  tripType: RebookSummaryTripType;
}

export enum RebookSummaryFailureEnum {
  ItineraryNotFound = "ItineraryNotFound",
  ContractNotFound = "ContractNotFound",
  MissingCoverageOrFareClass = "MissingCoverageOrFareClass",
  InvalidRoute = "InvalidRoute",
  ShopSummaryFailed = "ShopSummaryFailed",
  Unknown = "Unknown",
  RebookNotEligible = "RebookNotEligible",
}

export type RebookSummaryFailure =
  | ContractNotFound
  | InvalidRoute
  | ItineraryNotFound
  | MissingCoverageOrFareClass
  | RebookNotEligible
  | ShopSummaryFailed
  | Unknown;

export interface ContractNotFound {
  RebookSummaryFailure: RebookSummaryFailureEnum.ContractNotFound;
}

export interface InvalidRoute {
  RebookSummaryFailure: RebookSummaryFailureEnum.InvalidRoute;
}

export interface ItineraryNotFound {
  RebookSummaryFailure: RebookSummaryFailureEnum.ItineraryNotFound;
}

export interface MissingCoverageOrFareClass {
  RebookSummaryFailure: RebookSummaryFailureEnum.MissingCoverageOrFareClass;
}

export interface RebookNotEligible {
  RebookSummaryFailure: RebookSummaryFailureEnum.RebookNotEligible;
}

export interface ShopSummaryFailed {
  RebookSummaryFailure: RebookSummaryFailureEnum.ShopSummaryFailed;
}

export interface Unknown {
  message: string;
  RebookSummaryFailure: RebookSummaryFailureEnum.Unknown;
}

export enum PriceDropSmokeEligibilityEnum {
  PriceDropEligible = "Eligible",
  PriceDropNonEligible = "NonEligible",
}

export interface PriceDropEligible {
  amount: Amount;
  PriceDropSmokeEligibility: PriceDropSmokeEligibilityEnum.PriceDropEligible;
}

export interface PriceDropNonEligible {
  reason: string;
  PriceDropSmokeEligibility: PriceDropSmokeEligibilityEnum.PriceDropNonEligible;
}

export type PriceDropSmokeEligibility =
  | PriceDropEligible
  | PriceDropNonEligible;

export interface PriceDropSmokeEligibilityResponse {
  eligibility: PriceDropSmokeEligibility;
}
