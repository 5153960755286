import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { VRBookingErrorModal } from "./component";
import {
  getIsBookingValid,
  getModalType,
  getPollVacationRentalsPriceQuoteCallState,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  getVRErrorTitles,
  getVRHasError,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  scheduleBook,
  acknowledgePriceDifference,
  resetBookErrors,
} from "../../actions/actions";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getPetsCount,
  getUntilDate,
} from "../../../search/reducer";
import { CallState } from "redmond";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  hasError: getVRHasError(state),
  errorTitles: getVRErrorTitles(state),
  paymentMethodId: getSelectedPaymentMethodId(state),
  currentSession: getSession(state),
  selectedTravelerId: getUserSelectedTravelerId(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  selectedListing: getVacationRentalShopSelectedListing(state),
  childrenCount: getChildrenCount(state),
  adultsCount: getAdultsCount(state),
  isBookingValid: getIsBookingValid(state),
  priceQuoteSuccessful:
    getPollVacationRentalsPriceQuoteCallState(state) === CallState.Success,
  modalType: getModalType(state),
  petsCount: getPetsCount(state),
});

const mapDispatchToProps = {
  scheduleBook,
  resetBookErrors,
  acknowledgePriceDifference,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VRBookingErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedVRBookingErrorModal = withRouter(
  connector(VRBookingErrorModal)
);
