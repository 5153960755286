import { fetchLocationsSaga } from "./fetchLocationsSaga";
import { fetchLifestyleLocationsSaga } from "./fetchLifestyleLocationsSaga";
import { actionTypes } from "../actions";
import { takeLatest } from "redux-saga/effects";
import { fetchVacationRentalsLocationsSaga } from "./fetchVacationRentalsLocationsSaga";
import { fetchMegaMenuRegionsSaga } from "./fetchMegaMenuRegionsSaga";

export function* watchFetchLocationCategories() {
  yield takeLatest(actionTypes.FETCH_LOCATION_CATEGORIES, fetchLocationsSaga);
}

export function* watchFetchLifestyleLocationCategories() {
  yield takeLatest(
    actionTypes.FETCH_LIFESTYLE_LOCATION_CATEGORIES,
    fetchLifestyleLocationsSaga
  );
}

export function* watchFetchVacationRentalsLocationCategories() {
  yield takeLatest(
    actionTypes.FETCH_VACATION_RENTALS_LOCATION_CATEGORIES,
    fetchVacationRentalsLocationsSaga
  );
}

export function* watchMegaMenuRegions() {
  yield takeLatest(
    actionTypes.FETCH_MEGAMENU_REGIONS,
    fetchMegaMenuRegionsSaga
  );
}