import React from "react";
import { ActionButton, HotelPriceAndRewardsDisplay } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { MobileListingTotalPanelConnectorProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import { goToVacationRentalsCheckout } from "../../../shop/utils/queryStringHelpers";
export interface MobileListingTotalPanelProps
  extends MobileListingTotalPanelConnectorProps,
    RouteComponentProps {
  isSkeleton?: boolean;
}

export const MobileListingTotalPanel = (
  props: MobileListingTotalPanelProps
) => {
  const {
    nightCount,
    isSkeleton,
    rewardsKey,
    listing,
    history,
    setOpenDatesModal,
  } = props;
  const available = listing?.availability?.AvailabilityResult === "Available";
  return (
    <Box className={clsx("mobile-listing-total-panel-container")}>
      {available ? (
        <>
          <HotelPriceAndRewardsDisplay
            lodgingPrice={listing?.availability.rate?.price}
            nightCount={nightCount ?? 0}
            isSkeleton={isSkeleton}
            rewardsKey={rewardsKey}
            variant={"starting-at"}
          />
          <ActionButton
            className="b2b"
            onClick={() => {
              goToVacationRentalsCheckout({ history });
            }}
            message={
              <Typography variant="h2" className="button-text">
                {textConstants.RESERVE_CTA_TEXT}
              </Typography>
            }
          />
        </>
      ) : (
        <>
          <Typography className="unavailable-text">
            {textConstants.DATES_UNAVAILABLE_TEXT}
          </Typography>
          <ActionButton
            className="b2b"
            onClick={() => setOpenDatesModal(true)}
            message={
              <Typography variant="h2" className="button-text">
                {textConstants.CHANGE_DATES}
              </Typography>
            }
          />
        </>
      )}
    </Box>
  );
};
