import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PaymentCard } from "./component";
import {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchProductToEarn,
} from "../../actions/actions";
import {
  getDeletePaymentMethodCallState,
  getListPaymentMethodsCallState,
  getPaymentMethods,
  getVerifyPaymentMethodCallState,
  getSelectedPaymentMethodId,
  getTotalCreditCardPaymentRequired,
  getIsCreditCardPaymentRequired,
  getRewardsPaymentAccount,
  getRewardsPaymentAccountReferenceId,
  getHasError,
  getIsStackedTravelWalletPaymentOnly,
  getIsTravelWalletCreditPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  getVacationRentalsIsStackedTravelWalletPaymentOnly,
  getVacationRentalsIsTravelWalletCreditPaymentOnly,
  getVacationRentalsIsTravelWalletOfferPaymentOnly,
  getVacationRentalsIsCreditCardPaymentRequired,
  getTotalVacationRentalsCreditCardPaymentRequired,
} from "../../reducer";
import {
  getAllowRewardsWithPolicy,
  getRewardsAccounts,
} from "../../../rewards/reducer";
import { fetchRewardsAccounts } from "../../../rewards/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getPremierCollectionShopSelectedAvailability } from "../../../shop/reducer";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedHome = getVacationRentalShopSelectedListing(state);
  return {
    paymentMethods: getPaymentMethods(state),
    rewardsAccounts: getRewardsAccounts(state),
    listPaymentMethodCallState: getListPaymentMethodsCallState(state),
    verifyPaymentMethodCallState: getVerifyPaymentMethodCallState(state),
    deletePaymentMethodCallState: getDeletePaymentMethodCallState(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state),
    selectedRewardsPaymentAccount: getRewardsPaymentAccount(state),
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    isCreditCardPaymentRequired: selectedHome
      ? getVacationRentalsIsCreditCardPaymentRequired(state)
      : getIsCreditCardPaymentRequired(state),
    totalCreditCardPaymentRequired: selectedHome
      ? getTotalVacationRentalsCreditCardPaymentRequired(state)
      : getTotalCreditCardPaymentRequired(state),
    hasError: getHasError(state),
    isStackedTravelWalletPaymentOnly: selectedHome
      ? getVacationRentalsIsStackedTravelWalletPaymentOnly(state)
      : getIsStackedTravelWalletPaymentOnly(state),
    isTravelCreditPaymentOnly: selectedHome
      ? getVacationRentalsIsTravelWalletCreditPaymentOnly(state)
      : getIsTravelWalletCreditPaymentOnly(state),
    isTravelWalletOfferPaymentOnly: selectedHome
      ? getVacationRentalsIsTravelWalletOfferPaymentOnly(state)
      : getIsTravelWalletOfferPaymentOnly(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    selectedHome: selectedHome,
    canRedeemRewards: getAllowRewardsWithPolicy(state),
  };
};

const mapDispatchToProps = {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PaymentCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPaymentCard = withRouter(connector(PaymentCard));
