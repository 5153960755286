import { actions } from "../actions";
import { ListingSearchResult } from "redmond";
import {
  SELECT_HOME,
  SET_SELECTED_LISTING_ID,
  FETCH_VACATION_RENTAL_SHOP,
  SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED,
} from "../actions/constants";
import { ListingId } from "redmond/apis/tysons/vacation-rentals";
export interface IVacationRentalShopState {
  selectedHome: ListingSearchResult | null;
  vacationRentalListingId: ListingId | null;
  vacationRentalShopCallState: VacationRentalShopCallState;
  vacationRentalShopCallError?: VacationRentalShopCallError;
}

export enum VacationRentalShopCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum VacationRentalShopCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}

const initialState: IVacationRentalShopState = {
  selectedHome: null,
  vacationRentalListingId: null,
  vacationRentalShopCallState: VacationRentalShopCallState.NotCalled,
  vacationRentalShopCallError: undefined,
};

export const reducer = (
  state: IVacationRentalShopState = initialState,
  action: actions.VacationRentalShopActions
): IVacationRentalShopState => {
  switch (action.type) {
    case FETCH_VACATION_RENTAL_SHOP:
      return {
        ...state,
        vacationRentalShopCallState: VacationRentalShopCallState.InProcess,
      };
    case SELECT_HOME:
      return {
        ...state,
        selectedHome: action.home,
        vacationRentalShopCallState: VacationRentalShopCallState.Success,
      };

    case SET_SELECTED_LISTING_ID:
      return {
        ...state,
        vacationRentalListingId: action.id,
      };
    case SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        vacationRentalShopCallState: VacationRentalShopCallState.Failed,
        vacationRentalShopCallError: action.vrShopCallError,
      };
    default:
      return { ...state };
  }
};

export * from "./selectors";
