import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getLocation,
  getPetsCount,
  getStayType,
  getUntilDate,
} from "../../../search/reducer";
import {
  fetchPremierCollectionShop,
  setPremierCollectionShopProgress,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileShopHeader } from "./component";
import {
  getPremierCollectionShopSelectedAvailability,
  premierCollectionShopProgressSelector,
} from "../../reducer/selectors";
import { actions } from "../../../search/actions";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";
import { fetchVacationRentalShop } from "../../../vacation-rental-shop/actions/actions";
import { getOpenDatesModal } from "../../../availability/reducer";
import { setOpenDatesModal } from "../../../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    premierCollectionShopStep: premierCollectionShopProgressSelector(state),
    searchLocation: getLocation(state),
    selectedListing: getVacationRentalShopSelectedListing(state),
    petsCount: getPetsCount(state),
    stayType: getStayType(state),
    openDatesModal: getOpenDatesModal(state),
  };
};

const mapDispatchToProps = {
  fetchPremierCollectionShop,
  setFromDate: actions.setFromDate,
  setUntilDate: actions.setUntilDate,
  setPremierCollectionShopProgress,
  fetchVacationRentalShop,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopHeader = withRouter(
  connector(MobileShopHeader)
);
