import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { getSearchParams, getStayType } from "../../../search/reducer";
import {
  getCurrentSearchParams as getAvailabilityParams,
  getMapBound,
  getPremierCollectionAvailabilityLodgings,
  getVacationRentalAvailabilityListings,
} from "../../reducer";
import { PremierCollectionAvailabilitySearchControl } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodgings: getPremierCollectionAvailabilityLodgings(state) || [],
    vrListings: getVacationRentalAvailabilityListings(state) || [],
    stayType: getStayType(state),
    searchParams: getSearchParams(state),
    availabilityParams: getAvailabilityParams(state),
    isMapSearch: getMapBound(state) != null,
  };
};

const connector = connect(mapStateToProps);

export type AvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionAvailabilitySearchControl = connector(
  withRouter(PremierCollectionAvailabilitySearchControl)
);
