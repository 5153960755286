import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  setLocation,
  setLocationAutocompleteError,
} from "../../actions/actions";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getHasLocationAutocompleteError,
  getLocation,
  getMegaMenuRegions,
  getUntilDate,
} from "../../reducer";
import { MobilePremierCollectionSearchControlV3 } from "./component";

const mapDispatchToProps = {
  setLocation,
  setLocationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
  megaMenuRegions: getMegaMenuRegions(state),
  value: getLocation(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  adultsCount: getAdultsCount(state),
  children: getChildrenCount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePremierCollectionSearchV3ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePremierCollectionSearchControlV3 = withRouter(
  connector(MobilePremierCollectionSearchControlV3)
);
