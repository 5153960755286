import axios from "axios";

import { PaymentSessionToken } from "redmond";
import {
  purchaseApi,
  PaymentOpaqueValue,
  AncillaryOpaqueValue,
} from "@b2bportal/purchase-api";

const schedulePayment = (
  token: PaymentSessionToken,
  payments: Array<PaymentOpaqueValue>,
  ancillaries: AncillaryOpaqueValue[] = []
): Promise<String> =>
  new Promise((resolve, reject) => {
    try {
      const res = purchaseApi(axios).apiV0PurchaseFulfillSchedulePost({
        quoteToken: {
          value: token.value,
        },
        payments: payments,
        ancillaries,
      });

      res.then((body) => resolve(body.data.value)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });

export default schedulePayment;
