export const FETCH_VACATION_RENTAL_SHOP =
  "vacationRentalShop/FETCH_VACATION_RENTAL_SHOP";
export type FETCH_VACATION_RENTAL_SHOP = typeof FETCH_VACATION_RENTAL_SHOP;

export const SET_VACATION_RENTAL_SHOP_RESULTS =
  "vacationRentalShop/SET_VACATION_RENTAL_SHOP_RESULTS";
export type SET_VACATION_RENTAL_SHOP_RESULTS =
  typeof SET_VACATION_RENTAL_SHOP_RESULTS;

export const SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED =
  "vacationRentalShop/SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED";
export type SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED =
  typeof SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED;

export const SELECT_HOME = "vacationRentalShop/SELECT_HOME";
export type SELECT_HOME = typeof SELECT_HOME;

export const SET_SELECTED_LISTING_ID =
  "vacationRentalShop/SET_SELECTED_LISTING_ID";
export type SET_SELECTED_LISTING_ID = typeof SET_SELECTED_LISTING_ID;
