import React from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Typography } from "@material-ui/core";
import { GoogleMap, OverlayView, useGoogleMap } from "@react-google-maps/api";
import { PaneNames } from "@react-google-maps/api/dist/components/dom/OverlayView";
import clsx from "clsx";
import * as H from "history";
import { isEqual } from "lodash-es";
import { RouteComponentProps } from "react-router";

import { Icon, IconName, useDeviceTypes } from "halifax";
import {
  ListingSearchResult,
  Lodging,
  LodgingCollectionEnum,
  StayTypesEnum,
} from "redmond";

import { AvailabilityMapPricePin } from "./components";
import { ViewHotelsNearLocationAutocomplete } from "./components/ViewHotelsNearAutocomplete";
import { AvailabilityMapConnectorProps } from "./container";
import {
  convertGoogleMapBoundsToBoundingBox,
  convertICoordinatesToGoogleMapCoords,
} from "./googleMapsHelpers";

import "./styles.scss";

export interface IAvailabilityMapProps
  extends AvailabilityMapConnectorProps,
    RouteComponentProps {
  isPreview?: boolean;
  isLCForPremiumCardHoldersEnabled?: boolean;
  isLCForNonPremiumCardHoldersEnabled: boolean;
  isVRForPremiumCardHoldersEnabled: boolean;
  navigateToProperty: (
    property: Lodging | ListingSearchResult,
    index: number
  ) => void;
}

const MAP_LODGING_COUNT_LIMIT = 105;
const LODGING_HISTORY_COUNT = 3;
const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    stylers: [
      {
        saturation: -40,
      },
    ],
  },
  {
    featureType: "poi.business" as any,
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park" as any,
    elementType: "labels.text" as any,
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const AvailabilityMap = (props: IAvailabilityMapProps) => {
  const {
    isPreview,
    stayType,
    history,
    searchedLocation,
    setPropertyIdInFocus,
    fetchInitialPremierCollectionAvailability,
    setMapSearchQuery,
    // Experiments
    isLCForNonPremiumCardHoldersEnabled,
    isVRForPremiumCardHoldersEnabled,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const matchesDesktop = !matchesMobile;

  const [mapRef, setMapRef] = React.useState<google.maps.Map | null>(null);
  const [mapMoved, setMapMoved] = React.useState(false);
  React.useEffect(() => {
    setMapMoved(false);
  }, [searchedLocation]);

  const [mapSearchCoordinates, setMapSearchCoordinates] =
    React.useState<null | { lat: number; lon: number }>(null);

  const mapHeight = () => {
    if (matchesDesktop) {
      // note: it has to subtract the height of header
      return "100%";
    } else if (isPreview) {
      return "80px";
    } else {
      return window.innerHeight;
    }
  };

  const searchMapBounds: (() => void) | null = React.useMemo(() => {
    if (!mapMoved) return null;
    switch (stayType) {
      case StayTypesEnum.Hotels: {
        return () => {
          setMapMoved(false);
          const mapBoundFromGoogleMaps = mapRef?.getBounds();
          if (mapBoundFromGoogleMaps) {
            const mapBounds = convertGoogleMapBoundsToBoundingBox(
              mapBoundFromGoogleMaps
            );

            fetchInitialPremierCollectionAvailability(
              history,
              LodgingCollectionEnum.Premier,
              { mapBounds }
            );
          }
        };
      }
      case StayTypesEnum.VacationRentals:
        return null;
    }
  }, [stayType, mapMoved]);

  return (
    <Box
      className={clsx("pc-availability-map-holder", {
        preview: isPreview,
        expanded: !isPreview,
        mobile: matchesMobile,
        "only-lifestyle-collection": isLCForNonPremiumCardHoldersEnabled,
      })}
      onClick={() => {
        setPropertyIdInFocus(null);
      }}
    >
      {searchMapBounds && (
        <div
          className={clsx("map-search-button-wrapper", {
            mobile: matchesMobile,
          })}
        >
          <Button
            className={clsx("map-search-button")}
            onClick={searchMapBounds}
          >
            <Icon name={IconName.MagnifyingGlass} />
            <Typography className="map-search-button-text">
              Search this area
            </Typography>
          </Button>
        </div>
      )}
      {!isPreview ? (
        <div
          className={clsx("view-hotels-near-wrapper", {
            mobile: matchesMobile,
          })}
        >
          <ViewHotelsNearLocationAutocomplete
            isVRForPremiumCardHoldersEnabled={isVRForPremiumCardHoldersEnabled}
            isLCForNonPremiumCardHoldersEnabled={
              isLCForNonPremiumCardHoldersEnabled
            }
            className={clsx("view-hotels-near-auto-complete", "b2b", {
              mobile: matchesMobile,
            })}
            label={"View hotels near..."}
            isMobile={matchesMobile}
            clearValue={false}
            getOptionSelected={(a, b) => isEqual(a.id, b?.id)}
            customIcon={
              <Icon
                name={IconName.MagnifyingGlass}
                ariaLabel=""
                aria-hidden={true}
              />
            }
            endIcon={mapSearchCoordinates ? faTimesCircle : undefined}
            endIconOnClick={() => {
              setMapSearchCoordinates(null);
              setMapSearchQuery("");
            }}
          />
        </div>
      ) : null}
      <Box className="pc-availability-map-wrapper">
        <GoogleMap
          id={clsx("pc-availability-map", {
            mobile: matchesMobile,
            preview: isPreview,
          })}
          mapContainerStyle={{
            height: mapHeight(),
            width: "100%",
            borderRadius: isPreview ? "4px" : undefined,
          }}
          onDrag={() => setMapMoved(true)}
          zoom={11}
          options={{
            clickableIcons: false,
            zoomControl: isPreview ? false : matchesDesktop,
            streetViewControl: false,
            mapTypeControl: false,
            disableDoubleClickZoom: true,
            styles: MAP_STYLES,
            fullscreenControl: isPreview ? false : matchesDesktop,
            ...(isPreview && { gestureHandling: "none" }),
          }}
        >
          <AvailabilityMapContent
            mapProps={props}
            mapRef={mapRef}
            setMapRef={setMapRef}
            history={history}
          />
        </GoogleMap>
      </Box>
    </Box>
  );
};

const AvailabilityMapContent = (props: {
  mapProps: IAvailabilityMapProps;
  mapRef: google.maps.Map | null;
  setMapRef: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
  history: H.History;
}) => {
  const {
    mapProps: {
      lodgings,
      searchLocation,
      propertyIdInFocus,
      propertyIdHovered,
      nightCount,
      setPropertyIdInFocus,
      isPreview,
      stayType,
      vacationRentalsListings,
      navigateToProperty,
    },
    mapRef,
    setMapRef,
  } = props;

  const { matchesDesktop } = useDeviceTypes();
  // note: on hotel availability screen, followUp search requests are being made constantly;
  // it should recenter mapRef only on the first searchLocation update
  const initialLocationSearched = React.useRef(false);
  React.useEffect(() => {
    initialLocationSearched.current = false;
  }, [searchLocation]);

  const googleMap = React.useRef(useGoogleMap());
  React.useEffect(() => {
    setMapRef(googleMap.current);
  }, []);

  React.useEffect(() => {
    if (mapRef && searchLocation && !initialLocationSearched.current) {
      setPropertyIdInFocus(null);
      const searchLocationCoordinates = convertICoordinatesToGoogleMapCoords(
        "coordinates" in searchLocation
          ? searchLocation.coordinates
          : searchLocation.exactCoordinates
      );
      mapRef.setCenter(searchLocationCoordinates);
      initialLocationSearched.current = true;
    }
  }, [searchLocation, setPropertyIdInFocus, mapRef]);

  const propertyInFocus = React.useMemo(() => {
    if (stayType === StayTypesEnum.VacationRentals) {
      return vacationRentalsListings?.find(
        (listing) => listing.listingId.id === propertyIdInFocus
      );
    } else {
      return lodgings?.find(
        (lodging) => lodging.lodging.id === propertyIdInFocus
      );
    }
  }, [lodgings, propertyIdInFocus, stayType, vacationRentalsListings]);

  const lodgingHistoryCount = LODGING_HISTORY_COUNT;
  const [lodgingIdsPreviouslyShown, setLodgingIdsPreviouslyShown] =
    React.useState<string[]>([]);

  const useAddToLodgingIdsPreviouslyShown = (lodgingId: string | null) =>
    React.useEffect(() => {
      if (lodgingId) {
        setLodgingIdsPreviouslyShown((oldList) => {
          const newList = [
            ...oldList.filter((id) => id !== lodgingId),
            lodgingId,
          ];
          // Only take the newest 3 hotels in the history
          return newList.slice(-lodgingHistoryCount);
        });
      }
    }, [lodgingId]);

  useAddToLodgingIdsPreviouslyShown(propertyIdInFocus);
  useAddToLodgingIdsPreviouslyShown(propertyIdHovered);

  const useRecenterMap = ({
    property,
    disabled,
    viewPortRestricted,
  }: {
    property?: Lodging | ListingSearchResult;
    disabled?: boolean;
    viewPortRestricted?: boolean;
  }) =>
    React.useEffect(() => {
      if (disabled || property == null || mapRef == null) {
        return;
      }

      const projection = mapRef.getProjection();
      if (!projection) {
        return;
      }

      const propertyLatLng = convertICoordinatesToGoogleMapCoords(
        "lodging" in property
          ? property.lodging.location.coordinates
          : property.listing.content.location.exactCoordinates
      );
      const lodgingPoint = projection.fromLatLngToPoint(propertyLatLng);
      if (lodgingPoint) {
        // Move center up by window.innerHeight/4px (using the innerHeight instead of a fixed px due to different mobile sizes) to make space for the different heights of the hotel box on the bottom when a lodging is selected
        if (!matchesDesktop && propertyIdInFocus) {
          const scale = Math.pow(2, mapRef.getZoom() || 1);
          lodgingPoint.y = lodgingPoint.y + window.innerHeight / 4 / scale;
        } else {
          // Move center down by 150 pixels to make some space for the 300-pixel-tall hotel details box.
          const scale = Math.pow(2, mapRef.getZoom() || 1);
          lodgingPoint.y = lodgingPoint.y - 150 / scale;
        }
        const newLatLng = projection.fromPointToLatLng(lodgingPoint);
        if (
          newLatLng &&
          (!viewPortRestricted || !mapRef.getBounds()?.contains(newLatLng))
        ) {
          mapRef.panTo(newLatLng);
        }
      }
    }, [property, disabled, viewPortRestricted]);

  if (!isPreview) {
    useRecenterMap({ property: propertyInFocus });
  }

  const renderPricePin = React.useCallback(
    (
      mapPaneName: PaneNames,
      property: Lodging | ListingSearchResult,
      index: number
    ) => {
      if (!property || ("lodging" in property && !property.price)) {
        return null;
      }

      const coordinates = convertICoordinatesToGoogleMapCoords(
        "lodging" in property
          ? property.lodging.location.coordinates
          : property.listing.content.location.exactCoordinates
      );

      const propertyId =
        "lodging" in property ? property.lodging.id : property.listingId.id;
      return (
        <OverlayView
          key={propertyId}
          mapPaneName={mapPaneName}
          position={coordinates}
        >
          <AvailabilityMapPricePin
            isPreview={isPreview}
            isDesktop={matchesDesktop}
            property={property}
            isInFocus={!isPreview && propertyIdInFocus === propertyId}
            isHovered={
              !isPreview &&
              (propertyIdHovered === propertyId ||
                propertyIdInFocus === propertyId)
            }
            isPreviouslyShown={lodgingIdsPreviouslyShown.includes(propertyId)}
            goToPropertyDetails={() => {
              navigateToProperty(property, index);
            }}
            setPropertyIdInFocus={setPropertyIdInFocus}
          />
        </OverlayView>
      );
    },
    [
      isPreview,
      propertyIdInFocus,
      propertyIdHovered,
      lodgingIdsPreviouslyShown,
      nightCount,
      setPropertyIdInFocus,
    ]
  );
  return stayType === StayTypesEnum.Hotels ? (
    <>
      {lodgings.map((lodging, newIndex) => {
        const mustShow =
          lodging.lodging.id === propertyIdInFocus ||
          lodging.lodging.id === propertyIdHovered ||
          lodgingIdsPreviouslyShown.includes(lodging.lodging.id);
        if (newIndex > MAP_LODGING_COUNT_LIMIT && !mustShow) {
          return null;
        }
        return renderPricePin(
          OverlayView.OVERLAY_MOUSE_TARGET,
          lodging,
          newIndex
        );
      })}
    </>
  ) : (
    <>
      {vacationRentalsListings?.map((listing, newIndex) => {
        const mustShow =
          listing.listingId.id === propertyIdInFocus ||
          listing.listingId.id === propertyIdHovered ||
          lodgingIdsPreviouslyShown.includes(listing.listingId.id);
        if (newIndex > MAP_LODGING_COUNT_LIMIT && !mustShow) {
          return null;
        }
        return renderPricePin(
          OverlayView.OVERLAY_MOUSE_TARGET,
          listing,
          newIndex
        );
      })}
    </>
  );
};
