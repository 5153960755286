import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { VacationRentalShopConnectorProps } from "./container";
import { CallState, StayTypesEnum, VIEWED_VR_DETAILS } from "redmond";
import {
  useExperiments,
  VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  AVAILABLE,
  getExperimentVariant,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
} from "../../context/experiments";
import queryStringParser from "query-string";
import { PATH_VACATION_RENTALS_SHOP } from "../../utils/paths";
import { Box } from "@material-ui/core";
import { VRDesktopShop } from "./components/VRDesktopShop";
import { VRMobileShop } from "./components/VRMobileShop";
import { useDeviceTypes } from "halifax";
import { trackEvent } from "../../api/v0/analytics/trackEvent";

export interface IVacationRentalShopProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {}

export const VacationRentalShop = (props: IVacationRentalShopProps) => {
  const {
    history,
    fetchVacationRentalShop,
    setStayType,
    fetchedVacationRentalDetails,
    viewedVacationRentalDetailsProperties,
    listPaymentMethods,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const expState = useExperiments();

  const VRForPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForPremiumCardHoldersEnabled = useMemo(
    () => VRForPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const VRForNonPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForNonPremiumCardHoldersEnabled = useMemo(
    () => VRForNonPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  useEffect(() => {
    listPaymentMethods();
  }, []);

  useEffect(() => {
    if (fetchedVacationRentalDetails) {
      trackEvent({
        eventName: VIEWED_VR_DETAILS,
        ...viewedVacationRentalDetailsProperties,
      });
    }
  }, [fetchedVacationRentalDetails]);

  useEffect(() => {
    if (
      history.location.pathname === PATH_VACATION_RENTALS_SHOP &&
      (isVRForPremiumCardHoldersEnabled || isVRForNonPremiumCardHoldersEnabled)
    ) {
      setStayType(StayTypesEnum.VacationRentals);
    } else {
      setStayType(StayTypesEnum.Hotels);
    }
  }, [
    history.location.pathname,
    isVRForPremiumCardHoldersEnabled,
    isVRForNonPremiumCardHoldersEnabled,
  ]);

  useEffect(() => {
    if (
      [CallState.Success, CallState.Failed].includes(expState.callState) ||
      expState.experiments.length > 0
    ) {
      if (
        isVRForPremiumCardHoldersEnabled ||
        isVRForNonPremiumCardHoldersEnabled
      ) {
        fetchVacationRentalShop(
          history,
          {
            overrideStateByQueryParams: true,
          },
          isVRForPremiumCardHoldersEnabled
        );
      }
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [expState.callState, queryString.listingId]);

  return (
    <Box className={"vacation-rental-shop-root"}>
      {matchesDesktop && (
        <VRDesktopShop
          isVRForPremiumCardHoldersEnabled={isVRForPremiumCardHoldersEnabled}
        />
      )}
      {matchesMobile && (
        <VRMobileShop
          isVRForPremiumCardHoldersEnabled={isVRForPremiumCardHoldersEnabled}
        />
      )}
    </Box>
  );
};
