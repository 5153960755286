import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMediaQuery } from "@material-ui/core";
import {
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardTopSectionComponent,
  GenericDetailsCardTopSectionComponentEnum,
  ErrorOutsideComponentType,
  CfarImageDesktop,
  CfarImageMobile,
  IconName,
  useDeviceTypes,
} from "halifax";
import {
  HotelCfarQuote,
  HotelCancellationPolicyV2,
  HotelCancellationPolicyV2Enum,
  CFAR_HEADER,
  HOTEL_CFAR_POINT_ONE,
  HOTEL_CFAR_POINT_TWO,
  HOTEL_CFAR_POINT_THREE,
  HOTEL_CFAR_TERMS_BODY,
  HOTEL_CFAR_TERMS_TABLE,
} from "redmond";
import clsx from "clsx";
import { ClientContext } from "../../../../../../App";
import { PATH_TERMS_HOTEL_CFAR } from "../../../../../../utils/paths";
import { goToCheckout } from "../../../../../shop/utils/queryStringHelpers";
import { DO_NOT_APPLY_OPTION_KEY } from "../../../../reducer";
import { CfarDetailsConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

enum CfarOption {
  No,
  Yes,
}

export enum ErrorFromInsideComponentType {
  NoOptionSelected,
}

type CfarDetailsType = "refundable-room" | "hotel-cfar";

export interface ICfarDetailsProps
  extends CfarDetailsConnectorProps,
    RouteComponentProps {
  openCfarDetails?: boolean;
  onClose?: () => void;
  // Note: Overwrite the default continue action
  onContinue?: () => void;
  contentOnly?: boolean;
  cardContentProps?: {
    hideTopContent?: boolean;
    hideSubtitle?: boolean;
    hideContinueCta?: boolean;
    hideRadioButtons?: boolean;
    disablePartialScroll?: boolean;
    readTermsAndConditionsLinkPosition?: "left" | "center" | "right";
    onSelectValueCallback?: () => void;
    onConfirmSelectedValueCallback?: () => void;
    error?: ErrorOutsideComponentType;
  };
  disabled?: boolean;
  modalType?: CfarDetailsType;
  contentClassName?: string;
  currentHotelCfarQuote: HotelCfarQuote;
  currentCancellationPolicy: HotelCancellationPolicyV2 | null;
}

export const CfarDetails = (props: ICfarDetailsProps) => {
  const {
    history,
    openCfarDetails,
    onClose,
    onContinue = () => {
      goToCheckout({ history });
    },
    currentHotelCfarQuote,
    currentCancellationPolicy,
    selectedCfarId,
    setSelectedCfarId,
    selectedAccountReferenceId,
    contentOnly,
    cardContentProps,
    disabled,
    modalType = "hotel-cfar",
    contentClassName,
  } = props;
  const {
    hideTopContent,
    hideSubtitle,
    hideContinueCta,
    hideRadioButtons,
    disablePartialScroll,
    readTermsAndConditionsLinkPosition,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    error,
  } = cardContentProps ?? {};
  const { isAgentPortal } = useContext(ClientContext);
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const usePartialScroll =
    !disablePartialScroll &&
    useMediaQuery(constants.PARTIAL_SCROLL_THRESHOLD, {
      noSsr: true,
    });

  const [errorFromInsideComponent, setErrorFromInsideComponent] =
    useState<ErrorFromInsideComponentType | null>(null);

  let selectedValue: CfarOption | undefined;

  if (isAgentPortal) {
    selectedValue = CfarOption.No;
  } else if (selectedCfarId !== null) {
    if (selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY) {
      selectedValue = CfarOption.Yes;
    } else {
      selectedValue = CfarOption.No;
    }
  }

  const isUnselected = selectedValue === undefined;

  const getTopContent = (): GenericDetailsCardTopSectionComponent => {
    const header = (() => {
      switch (modalType) {
        case "refundable-room":
          return matchesMobile
            ? constants.REFUNDABLE_ROOM_TITLE_SEPARATE_LINES
            : constants.REFUNDABLE_ROOM_TITLE;
        case "hotel-cfar":
        default:
          return CFAR_HEADER({
            textVersion: "short",
            punctuation: matchesDesktop ? "." : undefined,
          });
      }
    })();

    return {
      className: "hotel-cfar-details-header-with-image",
      image: matchesMobile ? CfarImageMobile : CfarImageDesktop,
      location: constants.LOCATION_TEXT,
      header,
      component:
        GenericDetailsCardTopSectionComponentEnum.HeaderWithImageBackground,
    };
  };

  const handleViewTerms = () => {
    window.open(PATH_TERMS_HOTEL_CFAR, "_blank")?.focus();
  };

  const MainContent = useMemo((): GenericDetailsCardComponent[] => {
    const mainContent: GenericDetailsCardComponent[] = [];
    const continueCta = ((): GenericDetailsCardComponent => {
      const ctaMessage =
        modalType === "refundable-room"
          ? constants.UPGRADE_AND_CONTINUE_BUTTON_COPY
          : constants.CONTINUE_BUTTON_COPY;
      const isSelectionEnabled = getIsSelectionEnabled(modalType);

      return {
        message: ctaMessage,
        onClick: onContinue,
        onClickWhenDisabled: () => {
          if (isSelectionEnabled && !selectedValue) {
            setErrorFromInsideComponent(
              ErrorFromInsideComponentType.NoOptionSelected
            );
          }
        },
        ariaLabel: ctaMessage,
        disabled: isSelectionEnabled ? isUnselected : false,
        floating: matchesMobile,
        hidden: isSelectionEnabled ? matchesMobile && isUnselected : false,
        fill: "blue",
        component: GenericDetailsCardComponentEnum.GenericCta,
      };
    })();

    // Error can be tracked from:
    // 1) The continue button outside of this modal clicked. This is signaled via the error
    //    passed along in the props
    // 2) The continue button of this modal clicked.
    const hasError =
      error === ErrorOutsideComponentType.NoOptionSelected ||
      errorFromInsideComponent ===
        ErrorFromInsideComponentType.NoOptionSelected;

    const radioTitleId = `hotel-cfar-radio-group`;

    switch (modalType) {
      case "refundable-room":
        mainContent.push({
          id: radioTitleId,
          className: clsx(
            "hotel-cfar-details-generic-copy",
            "refundable-room-subtitle",
            "bold"
          ),
          type: "secondary",
          copy: constants.REFUNDABLE_ROOM_SUBTITLE(
            currentHotelCfarQuote.premiumPrices.fiat,
            selectedAccountReferenceId
              ? currentHotelCfarQuote.premiumPrices.rewards[
                  selectedAccountReferenceId
                ]
              : undefined
          ),
          component: GenericDetailsCardComponentEnum.GenericCopy,
        });
        break;
      case "hotel-cfar":
      default:
        if (!hideSubtitle) {
          mainContent.push({
            id: radioTitleId,
            className: clsx("hotel-cfar-details-generic-copy", "bold"),
            type: "secondary",
            copy: constants.CFAR_DETAILS_SUBTITLE,
            component: GenericDetailsCardComponentEnum.GenericCopy,
          });
        }
        break;
    }

    mainContent.push({
      className: clsx("hotel-cfar-details-check-mark-icon", {
        fade: disabled,
      }),
      type: "secondary",
      copy: HOTEL_CFAR_POINT_ONE,
      icon: IconName.NotAllowedSignBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });

    mainContent.push({
      className: clsx("hotel-cfar-details-check-mark-icon", "with-money-icon", {
        fade: disabled,
      }),
      type: "secondary",
      copy: HOTEL_CFAR_POINT_TWO(currentHotelCfarQuote.coveragePercentage),
      icon: IconName.MoneyCounterClockwiseBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });

    mainContent.push({
      className: clsx("hotel-cfar-details-check-mark-icon", {
        fade: disabled,
      }),
      type: "secondary",
      copy: HOTEL_CFAR_POINT_THREE,
      icon: IconName.SimpleLaptopBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });

    mainContent.push({
      copy:
        currentCancellationPolicy?.CancellationPolicyV2 !==
        HotelCancellationPolicyV2Enum.Unknown
          ? currentCancellationPolicy?.cfarInfoBannerText ??
            constants.WARNING_BANNER_COPY
          : constants.WARNING_BANNER_COPY,
      fill: "orange",
      size: "small",
      hideLeftIcon: true,
      hideRightIcon: true,
      useLeftBorder: true,
      component: GenericDetailsCardComponentEnum.NotificationBanner,
    });

    if (hasError) {
      mainContent.push({
        className: "hotel-cfar-details-error-message",
        type: "error",
        copy: constants.CFAR_NO_OPTION_SELECTED_COPY,
        component: GenericDetailsCardComponentEnum.GenericCopy,
        newSection: true,
      });
    }

    if (!hideRadioButtons) {
      mainContent.push({
        radioButtons: [
          {
            value: CfarOption.Yes,
            copy: constants.YES_OPTION_COPY(
              currentHotelCfarQuote.premiumPrices.fiat,
              selectedAccountReferenceId
                ? currentHotelCfarQuote.premiumPrices.rewards[
                    selectedAccountReferenceId
                  ]
                : undefined
            ),
            disabled: isAgentPortal,
          },
          {
            value: CfarOption.No,
            copy: constants.NO_OPTION_COPY,
          },
        ],
        selectedValue,
        setSelectedValue: (value: number) => {
          switch (value) {
            case CfarOption.Yes:
              !isAgentPortal && setSelectedCfarId(currentHotelCfarQuote.id);
              break;
            case CfarOption.No:
              setSelectedCfarId({
                value: DO_NOT_APPLY_OPTION_KEY,
              });
              break;
            default:
              break;
          }

          setErrorFromInsideComponent(null);

          if (onSelectValueCallback) {
            onSelectValueCallback();
          }
        },
        /*
          note: collapse only after activation to allow keyboard users to still hear
          the option selected and possibly navigate to other options.
        */
        onConfirmSelectedValue: () => {
          if (onConfirmSelectedValueCallback) {
            onConfirmSelectedValueCallback();
          }
        },
        hasError: hasError,
        radioGroupName: constants.CFAR_RADIO_BUTTONS_GROUP,
        ariaLabelledBy: radioTitleId,
        disabled: disabled,
        component: GenericDetailsCardComponentEnum.BoxedRadioGroup,
      });
    }

    if (!hideContinueCta && (!usePartialScroll || matchesMobile)) {
      mainContent.push(continueCta);
    }

    if (matchesMobile) {
      mainContent.push({
        content: [
          {
            title: constants.READ_TERMS_AND_CONDITIONS_COPY,
            body: HOTEL_CFAR_TERMS_BODY + HOTEL_CFAR_TERMS_TABLE,
          },
        ],
        component: GenericDetailsCardComponentEnum.AccordionGroup,
      });
    } else if (!usePartialScroll) {
      mainContent.push({
        message: constants.READ_TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        position: readTermsAndConditionsLinkPosition ?? "center",
        disabled: disabled,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      });
    }

    return mainContent;
  }, [
    currentHotelCfarQuote,
    selectedAccountReferenceId,
    isAgentPortal,
    selectedValue,
    onContinue,
    isUnselected,
    matchesMobile,
    usePartialScroll,
    hideContinueCta,
    readTermsAndConditionsLinkPosition,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    modalType,
    error,
  ]);

  const BottomContent = useMemo<
    GenericDetailsCardComponent[] | undefined
  >(() => {
    if (!usePartialScroll || matchesMobile) {
      return undefined;
    }

    const isSelectionEnabled = getIsSelectionEnabled(modalType);

    return [
      {
        message: constants.VIEW_TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      },
      {
        className: clsx(
          "hotel-cfar-details-bottom-banner-continue-button",
          modalType
        ),
        message:
          modalType === "refundable-room"
            ? constants.UPGRADE_AND_CONTINUE_BUTTON_COPY
            : constants.CONTINUE_BUTTON_COPY,
        onClick: onContinue,
        ariaLabel: constants.CONTINUE_BUTTON_COPY,
        disabled: isSelectionEnabled ? isUnselected : false,
        onClickWhenDisabled: () => {
          if (isSelectionEnabled && !selectedValue) {
            setErrorFromInsideComponent(
              ErrorFromInsideComponentType.NoOptionSelected
            );
          }
        },
        floating: false,
        fill: "blue",
        component: GenericDetailsCardComponentEnum.GenericCta,
      },
    ];
  }, [onContinue, isUnselected, matchesMobile, usePartialScroll, modalType]);

  useEffect(() => {
    // note: scroll to top upon arriving at this screen on mobile.
    if (matchesMobile) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [matchesMobile]);

  useEffect(() => {
    switch (modalType) {
      case "refundable-room":
        break;
      case "hotel-cfar":
      default:
        if (isAgentPortal) {
          setSelectedCfarId({
            value: DO_NOT_APPLY_OPTION_KEY,
          });
        }
    }
  }, [isAgentPortal, modalType]);

  return (
    <GenericDetailsCard
      popupClassName={`pc-${modalType}-generic-details-card`}
      contentClassName={contentClassName}
      topContent={!hideTopContent ? getTopContent() : undefined}
      mainContent={MainContent}
      bottomContent={BottomContent}
      // note: it's not rendered as a modal on mobile view
      openModal={openCfarDetails ?? matchesMobile}
      onClose={onClose}
      isMobile={matchesMobile}
      contentOnly={contentOnly ?? matchesMobile}
      scrollOption={usePartialScroll ? "partial-scroll" : "default-scroll"}
    />
  );
};

const getIsSelectionEnabled = (modalType: CfarDetailsType | undefined) => {
  return modalType === "hotel-cfar" || modalType === undefined;
};
