import dayjs from "dayjs";
import { createSelector } from "reselect";

import {
  ITrackingProperties,
  PremierCollectionEntryProperties,
  StatementCreditDetail,
  StayTypesEnum,
  VacationRentalEntryProperties,
} from "redmond";

import { IStoreState } from "../../../../reducers/types";
import {
  getAgentEmail,
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
} from "../../../rewards/reducer/selectors";
import { SearchParams } from "../../../shop/utils/queryStringHelpers";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";

export const getLocation = (state: IStoreState) =>
  state.premierCollectionSearch.location;

export const getLocationCategories = (state: IStoreState) =>
  state.premierCollectionSearch.locationCategories;

export const getLocationCategoriesLoading = (state: IStoreState) =>
  state.premierCollectionSearch.locationCategoriesLoading;

export const getFromDate = (state: IStoreState) =>
  state.premierCollectionSearch.fromDate;

export const getUntilDate = (state: IStoreState) =>
  state.premierCollectionSearch.untilDate;

export const getAdultsCount = (state: IStoreState) =>
  state.premierCollectionSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.premierCollectionSearch.children.length;

export const getChildren = (state: IStoreState) =>
  state.premierCollectionSearch.children;

export const getPetsCount = (state: IStoreState) =>
  state.premierCollectionSearch.petsCount;

export const getLocationSearchString = (state: IStoreState) =>
  state.premierCollectionSearch.locationSearchString;

export const getMegaMenuRegions = (state: IStoreState) =>
  state.premierCollectionSearch.megaMenuRegions;

export const getStayType = (state: IStoreState) =>
  state.premierCollectionSearch.stayType;

export const getVacationRentalsLocation = (state: IStoreState) =>
  state.premierCollectionSearch.vacationRentalsLocation;

export const getVacationRentalsLocationCategories = (state: IStoreState) =>
  state.premierCollectionSearch.vacationRentalsLocationCategories;

export const getVacationRentalsLocationCategoriesLoading = (
  state: IStoreState
) => state.premierCollectionSearch.vacationRentalsLocationCategoriesLoading;

export const getSearchParams = createSelector(
  getStayType,
  getVacationRentalsLocation,
  getLocation,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  (
    stayType,
    vacationRentalsLocation,
    hotelsLocation,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount
  ): SearchParams => {
    switch (stayType) {
      case StayTypesEnum.VacationRentals: {
        return {
          type: StayTypesEnum.VacationRentals,
          location: vacationRentalsLocation,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
          petsCount,
        };
      }
      case StayTypesEnum.Hotels: {
        return {
          type: StayTypesEnum.Hotels,
          location: hotelsLocation,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
        };
      }
    }
  }
);

export const getLocationCategoriesFilteredBySearchString = createSelector(
  getLocationCategories,
  getLocationSearchString,
  getStayType,
  getVacationRentalsLocationCategories,
  (
    locationCategories,
    searchString,
    stayType,
    vacationRentalsLocationCategories
  ) => {
    const categories =
      stayType === StayTypesEnum.Hotels
        ? locationCategories
        : vacationRentalsLocationCategories;
    if (categories) {
      if (!searchString) {
        return categories;
      }
      return categories.map((locationCategory) => ({
        ...locationCategory,
        results: locationCategory.results.filter((result) =>
          result.label.toLowerCase().includes(searchString.toLowerCase())
        ),
      }));
    }

    return [];
  }
);

export const getNightCount = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate): number | null => {
    if (fromDate && untilDate) {
      return dayjs(untilDate).diff(dayjs(fromDate), "day");
    } else {
      return null;
    }
  }
);

export const getPaymentMethods = (state: IStoreState) =>
  state.premierCollectionAvailability.paymentMethods;

export const getPremierCollectionEntryProperties = createSelector(
  getAgentEmail,
  getLocation,
  getFromDate,
  getIsFirstLaunch,
  getRewardsAccounts,
  getAdultsCount,
  getChildrenCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getPaymentMethods,
  (
    agentEmail,
    location,
    fromDate,
    isFirstLaunch,
    rewardsAccounts,
    numAdults,
    numChildren,
    credit,
    creditBreakdown,
    selectedAccount,
    paymentMethods
  ): ITrackingProperties<PremierCollectionEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: location?.label || "",
        // this is the best effort way to parse the country out of the location label
        // this value will not be correct all of the time since address are known to be difficult to parse
        // it should only be used for tracking purposes and not relied on for anything else
        country:
          location?.subLabel || location?.label?.split(", ")?.pop() || "",
        number_of_properties: 0,
        number_of_guests: numAdults + numChildren,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        rooms_searched: 1, // TODO: Update when multiroom is added for lhc
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        ...location?.trackingPropertiesV2?.properties,
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        customer_account_role: selectedAccount?.customerAccountRole,
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getHasLocationAutocompleteError = (state: IStoreState) =>
  state.premierCollectionSearch.hasLocationAutocompleteError;

export const getVacationRentalEntryProperties = createSelector(
  getAgentEmail,
  getVacationRentalsLocation,
  getFromDate,
  getIsFirstLaunch,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getPaymentMethods,
  (
    agentEmail,
    location,
    fromDate,
    isFirstLaunch,
    untilDate,
    numAdults,
    numChildren,
    credit,
    creditBreakdown,
    selectedAccount,
    paymentMethods
  ): ITrackingProperties<VacationRentalEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        customer_account_role: selectedAccount?.customerAccountRole,
        adults_count: numAdults,
        advance: fromDate ? dayjs(fromDate).diff(dayjs(), "day") : undefined,
        check_in_date: dayjs(fromDate).format("YYYY-MM-DD"),
        check_out_date: dayjs(untilDate).format("YYYY-MM-DD"),
        children_count: numChildren,
        destination_city: location?.label.split(",")?.length
          ? location?.label.split(",")?.length > 1
            ? location?.label.split(",")[0]
            : location?.label
          : "",
        destination_country:
          location?.subLabel || location?.label?.split(", ")?.pop() || "",
        los:
          fromDate && untilDate
            ? dayjs(untilDate).diff(fromDate, "day")
            : undefined,
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);
