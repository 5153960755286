import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setContactInfo,
  schedulePriceQuote,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  updateUserPassenger,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
} from "../../actions/actions";

import { IStoreState } from "../../../../reducers/types";
import { DesktopPremierCollectionBookWorkflow } from "./component";
import {
  getBookingProgress,
  getIsBookingValid,
  getPollPriceQuoteCallState,
  getPriceQuote,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  getConfirmationDetails,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  areAllStepsCompletedInCheckout,
  getPriceDifferenceAcknowledged,
  isTravelerStepComplete,
  isContactStepComplete,
  getCombinedBookingSteps,
  getPriceQuoteErrors,
  getConfirmationDetailsError,
  getIsTravelWalletPaymentOnly,
  getUserPassengers,
  getOpaquePayments,
  getPriceQuoteRequest,
} from "../../reducer";
import {
  getPremierCollectionShopCancellationSummary,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopChosenProductIndex,
  getPremierCollectionShopChosenRoomInfo,
  getPremierCollectionShopSelectedAvailability,
  hotelCfarQuoteFromChosenRoomProductSelector,
} from "../../../shop/reducer/selectors";
import {
  getAllAncillaries,
  hasCfarAttached,
  isCustomizePageEnabledSelector,
} from "../../../ancillary/reducer";
import { fetchTravelWalletCreditHistory } from "../../../travel-wallet/actions/actions";
import { getRewardsAccountWithLargestEarnForBanner } from "../../../rewards/reducer";
import { getFromDate, getUntilDate } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  const travelers = getUserPassengers(state);
  const selectedTravelerId = getUserSelectedTravelerId(state);
  const selectedTraveler = travelers.find(
    (traveler) => traveler.id === selectedTravelerId
  );
  return {
    priceQuote: getPriceQuote(state),
    priceQuoteCallState: getPollPriceQuoteCallState(state),
    currentSession: getSession(state),
    paymentMethodId: getSelectedPaymentMethodId(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    roomInfoProduct: getPremierCollectionShopChosenRoomInfo(state),
    chosenProductIndex: getPremierCollectionShopChosenProductIndex(state),
    checkoutProgress: getBookingProgress(state),
    combinedBookingSteps: getCombinedBookingSteps(state),
    isBookingValid: getIsBookingValid(state),
    reservation: getConfirmationDetails(state),
    email: getConfirmationEmail(state),
    phoneNumber: getConfirmationPhoneNumber(state),
    isTravelerStepComplete: isTravelerStepComplete(state),
    isContactStepComplete: isContactStepComplete(state),
    allStepsCompleted: areAllStepsCompletedInCheckout(state),
    priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
    priceQuoteErrors: getPriceQuoteErrors(state),
    confirmationDetailsErrors: getConfirmationDetailsError(state),
    isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
    isCustomizePageEnabled: isCustomizePageEnabledSelector(state),
    ancillaries: getAllAncillaries(state),
    hasCfarAttached: hasCfarAttached(state),
    hotelCfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
    selectedTraveler: selectedTraveler,
    priceQuoteRequest: getPriceQuoteRequest(state),
    opaquePayments: getOpaquePayments(state),
    checkIn: getFromDate(state),
    checkOut: getUntilDate(state),
    cancellationSummary: getPremierCollectionShopCancellationSummary(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
  schedulePriceQuote,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
  updateUserPassenger,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPremierCollectionBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopPremierCollectionBookWorkflow = withRouter(
  connector(DesktopPremierCollectionBookWorkflow)
);
