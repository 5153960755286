import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import clsx from "clsx";

import { PremierCollectionBookConnectorProps } from "./container";
import {
  BookingErrorModal,
  BookingInProgressModal,
  BookingSuccessModal,
  DesktopPremierCollectionBookWorkflow,
  MobilePremierCollectionBookWorkflow,
} from "./components";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_BOOK_CONFIRMATION,
} from "../../utils/paths";
import { goToShop } from "../shop/utils/queryStringHelpers";
import "./styles.scss";
import {
  HOTEL_CHECKOUT_TITLE,
  PORTAL_TITLE,
  VACATION_RENTAL_CHECKOUT_TITLE,
} from "./textConstants";
import {
  CallState,
  IPerson,
  LodgingCollectionEnum,
  REVIEW_DETAILS_LC_CHECKOUT,
  REVIEW_DETAILS_PC_CHECKOUT,
  REVIEW_DETAILS_VR_CHECKOUT,
} from "redmond";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import {
  useExperiments,
  getExperimentVariant,
  TREES_MODAL_EXPERIMENT,
  AVAILABLE,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
} from "../../context/experiments";
import { ClientContext } from "../../App";
import { MobileVacationRentalsBookWorkflow } from "./components/MobileVacationRentalsBookWorkflow";
import { DesktopVacationRentalsBookWorkflow } from "./components/DesktopVacationRentalsBookWorkflow";
import { VRBookingErrorModal } from "./components/VRBookingErrorModal";
import { BookingSubmitApprovalModal } from "./components/capone-corporate/BookingSubmitApprovalModal";
import { BookingSubmitApprovalErrorModal } from "./components/capone-corporate/BookingSubmitApprovalErrorModal";

export interface IPremierCollectionBookProps
  extends PremierCollectionBookConnectorProps,
    RouteComponentProps {
  isVacationRentalBooking?: boolean;
}

export const PremierCollectionBook = (props: IPremierCollectionBookProps) => {
  const {
    history,
    location,
    chosenProduct,
    selectedLodging,
    setPriceQuote,
    reviewPremierCollectionDetailsProperties,
    selectedHome,
    travelers,
    userPassengerCallState,
    viewedVacationRentalDetailsProperties,
    priceQuote,
    fetchApplicableTravelWalletItems,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [primaryTraveler, setPrimaryTraveler] = useState<IPerson | undefined>(
    undefined
  );
  const [searchedPrimaryTraveler, setSearchedPrimaryTraveler] = useState(false);

  const userInfo = useContext(ClientContext).sessionInfo?.userInfo;

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  useEffect(() => {
    if (!selectedHome && (!chosenProduct || !selectedLodging)) {
      goToShop({ history });
    }
  }, [selectedHome, chosenProduct, selectedLodging]);

  useEffect(() => {
    if (!!chosenProduct && !!priceQuote) {
      if (
        chosenProduct.tripTotal.fiat.value !==
        priceQuote.pricing.tripTotal.fiat.value
      ) {
        isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
      }
    }
  }, [chosenProduct, priceQuote]);

  useEffect(() => {
    setPriceQuote(null, null);
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [location.pathname]);

  useEffect(() => {
    document.title = selectedHome
      ? VACATION_RENTAL_CHECKOUT_TITLE
      : HOTEL_CHECKOUT_TITLE;
    selectedHome
      ? trackEvent({
          eventName: REVIEW_DETAILS_VR_CHECKOUT,
          properties: {
            ...viewedVacationRentalDetailsProperties.properties,
          },
          encryptedProperties: [
            ...viewedVacationRentalDetailsProperties.encryptedProperties,
          ],
        })
      : trackEvent({
          eventName:
            selectedLodging?.lodgingCollection ===
            LodgingCollectionEnum.Lifestyle
              ? REVIEW_DETAILS_LC_CHECKOUT
              : REVIEW_DETAILS_PC_CHECKOUT,
          properties: {
            ...reviewPremierCollectionDetailsProperties.properties,
          },
          encryptedProperties: [
            ...reviewPremierCollectionDetailsProperties.encryptedProperties,
          ],
        });
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (userPassengerCallState === CallState.Success) {
      const savedPrimaryTraveler = travelers.find(
        (t) =>
          t.givenName === userInfo?.firstName && t.surname == userInfo.lastName
      );
      setSearchedPrimaryTraveler(true);
      if (!searchedPrimaryTraveler) {
        setPrimaryTraveler(savedPrimaryTraveler);
      }
    }
  }, [travelers, userPassengerCallState, searchedPrimaryTraveler]);

  return (
    <Box
      className={clsx(
        "hotel-book-root",
        { confirm: location.pathname === PATH_BOOK_CONFIRMATION },
        { mobile: matchesMobile }
      )}
    >
      <Route path={PATH_BOOK} exact>
        <>
          <Box className="hotel-book-container">
            {matchesDesktop && (
              <DesktopPremierCollectionBookWorkflow
                isTreesModalExperiment={isTreesModalExperiment}
                isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
                isCreditAndOfferStackingExperimentV1={
                  isCreditAndOfferStackingExperimentV1
                }
                isTravelCreditHistoryExperiment={
                  isTravelCreditHistoryExperiment
                }
              />
            )}
            {matchesMobile && (
              <MobilePremierCollectionBookWorkflow
                isTreesModalExperiment={isTreesModalExperiment}
                isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
                isTravelWalletCreditsExperiment={
                  isTravelWalletCreditsExperiment
                }
                isCreditAndOfferStackingExperimentV1={
                  isCreditAndOfferStackingExperimentV1
                }
                isTravelCreditHistoryExperiment={
                  isTravelCreditHistoryExperiment
                }
              />
            )}
          </Box>
          <BookingSubmitApprovalModal />
          <BookingSubmitApprovalErrorModal />
          <BookingErrorModal />
          <BookingInProgressModal />
        </>
      </Route>
      <Route path={PATH_VACATION_RENTALS_BOOK} exact>
        <Box className="vacation-rental-book-container">
          {matchesDesktop && (
            <DesktopVacationRentalsBookWorkflow
              isTreesModalExperiment={isTreesModalExperiment}
              isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
              isCreditAndOfferStackingExperimentV1={
                isCreditAndOfferStackingExperimentV1
              }
              isTravelCreditHistoryExperiment={isTravelCreditHistoryExperiment}
              primaryTraveler={primaryTraveler}
            />
          )}
          {matchesMobile && (
            <MobileVacationRentalsBookWorkflow
              isTreesModalExperiment={isTreesModalExperiment}
              isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
              isTravelWalletCreditsExperiment={isTravelWalletCreditsExperiment}
              isCreditAndOfferStackingExperimentV1={
                isCreditAndOfferStackingExperimentV1
              }
              isTravelCreditHistoryExperiment={isTravelCreditHistoryExperiment}
              primaryTraveler={primaryTraveler}
            />
          )}
        </Box>
        <VRBookingErrorModal />
        <BookingInProgressModal isBookingHome />
      </Route>
      <Route
        path={[PATH_BOOK_CONFIRMATION, PATH_VACATION_RENTALS_BOOK_CONFIRMATION]}
      >
        <BookingSuccessModal isTreesModalExperiment={isTreesModalExperiment} />
      </Route>
    </Box>
  );
};
