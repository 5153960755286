import { Dispatch } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";
import { ICategorizedResponse, IResult, StayTypesEnum } from "redmond";

import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getLocation,
  getLocationCategoriesFilteredBySearchString,
  getLocationCategoriesLoading,
  getLocationSearchString,
  getStayType,
  getVacationRentalsLocation,
} from "../../../../reducer";
import {
  AUTOCOMPLETE_RESULTS_GROUP_LABEL,
  HOTELS_WITH_VR_AUTOCMPLETE_RESULTS_LABEL,
  LC_AUTOCOMPLETE_RESULTS_GROUP_LABEL,
  PREMIUM_STAYS_AUTOCOMPLETE_RESULTS_GROUP_LABEL,
  VACATION_RENTALS_AUTOCOMPLETE_RESULTS_LABEL,
} from "../../textConstants";

interface IDispatchProps {
  setLocation: (location: IResult | null) => actions.ISetLocation;
  fetchValueCategories: (
    searchString: string
  ) => actions.ISetLocationSearchString;
  setVacationRentalsLocation: (
    location: IResult | null
  ) => actions.ISetVacationRentalsLocation;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setLocation: (location: IResult | null) =>
      dispatch(actions.setLocation(location)),
    fetchValueCategories: (searchString: string) =>
      dispatch(actions.setLocationSearchString(searchString)),
    setVacationRentalsLocation: (location: IResult | null) =>
      dispatch(actions.setVacationRentalsLocation(location)),
  };
};

interface IStateProps {
  value: IResult | null;
  valueCategories: ICategorizedResponse[];
  loading: boolean;
  callbackOnEmptyValue: true;
  groupHeadingText?: string;
  staysType?: StayTypesEnum;
}

interface IOwnProps {
  isLifestyleCollection?: boolean;
  includesLifestyleCollection?: boolean;
  includesVacationRentals?: boolean;
  overrideInputValue?: string;
}

export const mapStateToProps = (
  state: IStoreState,
  ownProps: IOwnProps
): IStateProps => {
  let loading = !!getLocationCategoriesLoading(state);
  const staysType = getStayType(state);

  return {
    value:
      staysType === StayTypesEnum.VacationRentals
        ? getVacationRentalsLocation(state)
        : getLocation(state),
    valueCategories: getLocationCategoriesFilteredBySearchString(state),
    loading,
    callbackOnEmptyValue: true,
    groupHeadingText: !!getLocationSearchString(state)
      ? undefined
      : staysType === StayTypesEnum.VacationRentals
      ? VACATION_RENTALS_AUTOCOMPLETE_RESULTS_LABEL
      : ownProps.includesVacationRentals
      ? HOTELS_WITH_VR_AUTOCMPLETE_RESULTS_LABEL
      : ownProps.isLifestyleCollection
      ? LC_AUTOCOMPLETE_RESULTS_GROUP_LABEL
      : ownProps.includesLifestyleCollection
      ? PREMIUM_STAYS_AUTOCOMPLETE_RESULTS_GROUP_LABEL
      : AUTOCOMPLETE_RESULTS_GROUP_LABEL,
    staysType,
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  const staysType = stateProps.staysType;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    setValue: (location: IResult | null) =>
      staysType === StayTypesEnum.VacationRentals
        ? dispatchProps.setVacationRentalsLocation(location)
        : dispatchProps.setLocation(location),
  };
};

export const ConnectedLocationAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<IResult | null>
  ) => JSX.Element
);
