import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../reducers/types";
import { listPaymentMethods } from "../availability/actions/actions";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../rewards/reducer";
import {
  fetchLifestyleLocationCategories,
  fetchLocationCategories,
  fetchMegaMenuRegions,
  fetchVacationRentalsLocationCategories,
  setStayType,
} from "./actions/actions";
import { PremierCollectionSearch } from "./component";
import { getStayType, getVacationRentalsLocationCategories } from "./reducer";

const mapStateToProps = (state: IStoreState) => ({
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  rewardsAccounts: getRewardsAccounts(state),
  stayType: getStayType(state),
  vacationRentalsLocationCategories:
    getVacationRentalsLocationCategories(state),
});

const mapDispatchToProps = {
  fetchRewardsAccounts,
  fetchMegaMenuRegions,
  fetchLocationCategories,
  fetchLifestyleLocationCategories,
  fetchVacationRentalsLocationCategories,
  setStayType,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionSearchConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionSearch = withRouter(
  connector(PremierCollectionSearch)
);
