import React from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  useDeviceTypes,
  VoidWindowNotice,
} from "halifax";
import {
  IPerson,
  PersonId,
  CallState,
  SELECT_TRAVELERS,
  ITravelerStepErrors,
  CancellationReason,
} from "redmond";
import clsx from "clsx";
import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";

import "./styles.scss";
import { PremierCollectionBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import {
  AVAILABLE,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface IPremierCollectionBookPassengerSelectionProps
  extends PremierCollectionBookPassengerSelectionConnectorProps {
  progress: TravelerSelectStep;
  setProgress: (progress: TravelerSelectStep) => void;
  onProgressChange?: (step: TravelerSelectStep) => void;
  onContinue?: (travelersChanged?: boolean) => void;
  onGoBack?: (travelersChanged?: boolean) => void;
  className?: string;
  selectionScreenHeaderElement?: JSX.Element;
  onReviewStep?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  combinedStep?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  saveButtonClicked?: boolean;
  useLocalId?: boolean;
  travelerToEdit?: IPerson;
  setTravelerToEdit?: (traveler?: IPerson) => void;
  preselectedUserId?: string;
}

export const PremierCollectionBookPassengerSelection = (
  props: IPremierCollectionBookPassengerSelectionProps
) => {
  const {
    className,
    travelers,
    progress,
    selectionScreenHeaderElement,
    setProgress,
    onProgressChange,
    onContinue,
    onGoBack,
    selectedTravelerId,
    setUserSelectedPassengerIds,
    updateUserPassenger,
    deleteUserPassenger,
    fetchUserPassengers,
    userPassengerCallState,
    onReviewStep,
    isMobile,
    disabled,
    combinedStep,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    useLocalId,
    travelerToEdit,
    preselectedUserId,
    fetchCorpUserPassengers,
    cancellationSummary,
  } = props;

  const [localTravelerId, setLocalTravelerId] = React.useState<string[]>([]);
  const currentTravelerId = useLocalId
    ? localTravelerId[0]
    : selectedTravelerId;
  const travelersChanged = selectedTravelerId !== localTravelerId[0];
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const streamlineTravelerProfileEnabled =
    useExperimentsById("corp-streamline-traveler-profile-fe")?.variant === "m2";

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = React.useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  React.useEffect(() => {
    streamlineTravelerProfileEnabled && isCorpTenant(config.TENANT)
      ? fetchCorpUserPassengers()
      : fetchUserPassengers();
  }, []);

  React.useEffect(() => {
    if (preselectedUserId) {
      setLocalTravelerId([preselectedUserId]);
    }
  }, [preselectedUserId]);
  React.useEffect(() => {
    if (travelers.length === 1) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: [travelers[0].id],
      });
    }
  }, [travelers]);

  const handleContinue = () => {
    if (useLocalId && travelersChanged) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: localTravelerId,
      });
    }
    onContinue && onContinue(travelersChanged);
  };

  const handleGoBack = () => {
    if (useLocalId && travelersChanged) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: localTravelerId,
      });
    }
    onGoBack && onGoBack(travelersChanged);
  };

  return userPassengerCallState === CallState.InProcess ? (
    <LoadingIndicator
      indicatorSize="small"
      indicator={B2BSpinner}
      message={
        userPassengerCallState === CallState.InProcess
          ? constants.UPDATE_TEXT
          : constants.VALIDATE_TEXT
      }
    />
  ) : (
    <TravelerSelectWorkflow
      displayModalSubtitle
      showGenderField
      showNationalityField
      singleTravelerWorkflow
      buttonClassName="b2b"
      className={clsx(
        "premier-collection-book-passenger-selection-root",
        className,
        {
          "combined-step": combinedStep,
        }
      )}
      disabled={disabled}
      errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
      handleDeletePassenger={(travelerId: PersonId) => {
        if (useLocalId) {
          if (localTravelerId[0] === travelerId) {
            setLocalTravelerId([]);
          }
        }
        deleteUserPassenger({ personId: travelerId });
      }}
      handleUpdatePassenger={(traveler: IPerson) => {
        if (useLocalId) {
          setLocalTravelerId([traveler.id]);
        }
        updateUserPassenger(
          { person: traveler },
          traveler.driverLicense !== undefined
        );
      }}
      isMobile={matchesMobile}
      onContinue={handleContinue}
      onGoBack={handleGoBack}
      onProgressChange={onProgressChange}
      progress={progress}
      saveButtonClicked={saveButtonClicked}
      selectedTravelerIds={currentTravelerId ? [currentTravelerId] : []}
      selectionScreenHeaderElement={selectionScreenHeaderElement}
      setProgress={setProgress}
      setSelectedTravelerIds={(travelerIds: string[]) => {
        trackEvent({
          eventName: SELECT_TRAVELERS,
          properties: {},
        });
        if (useLocalId) {
          setLocalTravelerId(travelerIds);
        } else {
          setUserSelectedPassengerIds({
            userSelectedPassengerIds: travelerIds,
          });
        }
      }}
      setShowErrors={setShowErrors}
      showErrors={showErrors}
      titles={{
        travelerInfoTitle: matchesMobile
          ? constants.TRAVELER_INFO_TEXT
          : constants.TRAVELER_INFO_TITLE_UPDATED,
        travelerInfoSubtitle: matchesMobile
          ? constants.TRAVELER_INFO_SUBTITLE_MOBILE
          : constants.TRAVELER_INFO_SUBTITLE,
        frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
        additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
        adultTitle: constants.ADULT_TITLE,
        childTitle: constants.CHILD_TITLE,
        infantSeatTitle: constants.INFANT_SEAT_TITLE,
        infantLapTitle: constants.INFANT_LAP_TITLE,
        addTravelers: matchesMobile
          ? onReviewStep
            ? constants.TRAVELER_INFO_TEXT
            : constants.ADD_TRAVELER_INFO_TEXT
          : constants.TRAVELER_INFO_TITLE_UPDATED,
      }}
      travelers={travelers}
      wrapButton={isMobile && !onReviewStep}
      editPassenger={travelerToEdit}
      tenant={config.TENANT}
      trackEvent={trackEvent}
      bottomContent={showFree24HourCancel ? <VoidWindowNotice /> : undefined}
    />
  );
};
